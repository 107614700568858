import React from 'react'
import Nav from '../component/inner_pages/Nav';
import './Setting.css'
import Sidebar from '../component/inner_pages/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faSetting } from '@fortawesome/free-solid-svg-icons'
import CopyToClipboard from 'react-copy-to-clipboard';
import { useEffect } from 'react';
import { AiFillFile, AiFillSetting } from "react-icons/ai";

function Settings() {
    const [api, setApi] = React.useState(localStorage.getItem('ApiKey'))

    // get request to regenerate api key
    const regenerate = () => {
        fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/regenerate`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            }
        })
            .then(res => res.json())
            .then(data1 => {
                console.log(data1)
                
                    localStorage.setItem('ApiKey', data1.Apikey)
                    setApi(data1.Apikey)
                
            })
    }

    useEffect(() => {
        if(!localStorage.getItem('ApiKey')){
        regenerate()}
    }, [])


    return (
        <>
            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 ">
                        

                        <div className='card'>
                            <div className='card-body'>
                                <div className='qr-list'>
                                    <div className="col-md-12">
                                        <div className="doc_list_step">
                                            <div className="doc_list_step_item">
                                                <h5 className="card-title"> <AiFillSetting /> Settings</h5>
                                            </div>
                                        </div>
                                        <div className="qrlist-container">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="qr-code-list-view setting-table-view">
                                                            <div className="qr-code-list-view-box setting-list-view">
                                                                <div className='qr-code-list-view-box-title'>
                                                                    <h5 className=''>Your Api Key : {api}</h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="action-btn">
                                                            <div className="action-btn-box">
                                                                <CopyToClipboard text={api}>
                                                                <button className="action-btn-item-view"><FontAwesomeIcon icon={faCopy } />Copy</button>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </td>
                                                        <td className="action-btn">
                                                            <div className="action-btn-box">

                                                            <button className="action-btn-item1" onClick={() => regenerate()}>Regenerate</button>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default Settings;