import React from 'react'
import { useEffect, useState } from 'react'
import Nav from '../component/inner_pages/Nav';
import Sidebar from '../component/inner_pages/Sidebar';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import Loading from "react-fullscreen-loading";
import { BarChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar } from 'recharts'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Statistics() {
    const history = useHistory()
    const location = useLocation()
    const [code, setCode] = useState('')
    const [total, setTotal] = useState(0)
    const [unique, setUnique] = useState(0)
    const [mobile, setMobile] = useState(0)
    const [desktop, setDesktop] = useState(0)
    const [windows, setWindows] = useState(0)
    const [ios, setIos] = useState(0)
    const [android, setAndriod] = useState(0)
    const [macos, setMac] = useState(0)
    const [other, setOther] = useState(0)
    const [loading, setLoading] = useState(false)
    const [campaignName, setCampaignName] = useState('')
    const [surl, setSurl] = useState('')
    const [furl, setFurl] = useState('')

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const data = [
        {
            "name": month[new Date().getMonth()],
            "UniqueCount": unique,
            "TotalCount": total
        }
    ]
    const data1 = [
        {
            "name": month[new Date().getMonth()],
            "Mobile": mobile,
            "Desktop": desktop
        }
    ]

    const data2 = [
        {
            "name": month[new Date().getMonth()],
            "ios": ios,
            "macos": macos,
            "android": android,
            "Windows": windows,
            
            
        }
    ]


    useEffect(() => {
        const getstatics = async () => {
            setLoading(true)
            const url = queryString.parse(location.search)
            setCode(url.code)
            if (!url.code) {
                history.goBack()
            }
            else {

                

                const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_VISITOR_URL}/statics/${url.code}`, {
                    headers: {
                        'x-access-token': localStorage.getItem('token')
                    }
                })
                const data = await req.json()
                console.log(data)

                setTotal(data.total)
                setUnique(data.unique)
                setMobile(data.mobile)
                setDesktop(data.desktop)
                setWindows(data.wos)
                setAndriod(data.aos)
                setMac(data.macos)
                setIos(data.ios)
                setOther(data.oos)

            }
            setLoading(false)
        }
        const getDetails = async () => {
            const url = queryString.parse(location.search)
            setCode(url.code)
                
            
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_VISITOR_URL}/campaignDetails/${url.code}`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })
            const data = await req.json()
            console.log(data)
            setCampaignName(data[0].campaignName)
            setSurl(data[0].code)
            setFurl(data[0].furl)
        }
        

        getstatics()
        getDetails()

    },[])
    return (
        <>
        <Loading loading={loading} loaderColor="#f16022" />;
            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--90">

                        <div className='header-title-dashboard'>
                            <h4 className="h2"><FontAwesomeIcon icon={faEye} /> View Stats</h4>
                        </div>

                        <div> 
                            <p>Session : {campaignName}  </p>
                            <p>SmartLink : {process.env.REACT_APP_SSL_CHECK}://{process.env.REACT_APP_VISITOR_URL}/{surl} </p>
                            <p>Destination : <a href={furl} style={{ color: 'blue' }}> {furl} </a></p>

                             
                            
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5>Visits</h5>
                                        <ResponsiveContainer width="95%" height={400}>
                                            <BarChart width={730} height={250} data={data}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="TotalCount" fill="#8884d8" />
                                                <Bar dataKey="UniqueCount" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <h5>Devices</h5>
                                        <ResponsiveContainer width="95%" height={400}>
                                            <BarChart width={730} height={250} data={data1}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="Desktop" fill="#8884d8" />
                                                <Bar dataKey="Mobile" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <h5>OS</h5>
                                        <ResponsiveContainer width="95%" height={400}>
                                            <BarChart width={730} height={250} data={data2}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="ios" fill="#1e0fff" />
                                                <Bar dataKey="android" fill="#ff0000" />
                                                <Bar dataKey="Windows" fill="#8884d8" />
                                                <Bar dataKey="macos" fill="#f8981c" />
                                                <Bar dataKey="other" fill="#82ca9d" />
                                                
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}


export default Statistics;
