import React from 'react';
import { useRef } from 'react';
import Nav from '../component/inner_pages/Nav';
import Sidebar from '../component/inner_pages/Sidebar';
import QRCodeStyling from "qr-code-styling";
import { useEffect } from 'react';
import './Qr.css';
import { useState } from 'react';
import validator from 'validator'
import { useScreenshot, createFileName } from "use-react-screenshot";
import Accordion1 from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faQrcode, faX, faServer, faCampground, faL } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { faBorderAll } from '@fortawesome/free-solid-svg-icons'
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons'
import ShortUniqueId from 'short-unique-id'
import { SketchPicker, BlockPicker } from "react-color";

import { faDownload } from '@fortawesome/free-solid-svg-icons'
import domToImage from "dom-to-image";
import download from "downloadjs";
  
let qrCode = new QRCodeStyling({
  width: 500,
  height: 500,
  image:
    "",
  imageOptions: {
    crossOrigin: "anonymous",
    imageSize: 0.6,
    margin: 20,
    hideBackgroundDots: false
  }
});
const dotTypes = ['rounded', 'dots', 'classy', 'classy-rounded', 'square', 'extra-rounded']
const eyesOptions = {
  sq: ['square', 'square'],
  sqd: ['square', 'dot'],
  dsq: ['dot', 'square'],
  d: ['dot', 'dot'],
  esq: ['extra-rounded', 'square'],
  ed: ['extra-rounded', 'dot']
}
const imageURLS = {
  fb: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/fb.png`,
  tw: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/tw.png`,
  ig: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/ig.png`,
  wh: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wh.png`,
  yt: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/yt.png`,
  sn: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/sn.png`,
  wg: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wg.png`,
  // tk: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`,
}

function Qr() {
  const ref = useRef(null);
  const downloadRef = useRef(null)

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const [image2, takeScreenShot2] = useScreenshot({
    type: "image/png",
    quality: 1.0
  });

  const [image3, takeScreenShot3] = useScreenshot({
    type: "image/svg+xml",
    quality: 1.0
  });

  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "0.3",
  });
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;

  //creating state to store our color and also set color using onChange event for block picker
  const [pickerVisibality, setpickerVisibality] = useState(false);

  const handleClick = () => {
    setpickerVisibality(!pickerVisibality);
  }


  const handleClickPicker = () => {
    if (pickerVisibality) {
      setpickerVisibality(false);
    }

  }


  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(true)
  const [text, setText] = useState('SCAN ME')
  const [show3, setShow3] = useState(false);
  const [domains, setDomains] = useState([])
  const [custom, setCustom] = useState(-1)
  const [dotColour, setDotColour] = useState('#000000')
  const [csqColor, setCsqColor] = useState('#000000')
  const [cdColor, setCdColor] = useState('#000000')
  const [bgcolor, setBgcolor] = useState('#ffffff')
  const [dotGradient, setDotGradient] = useState('')
  const [type, setType] = useState('Static')
  const [imgdata, setImgdata] = useState(null)
  const [hidden, setHidden] = useState(true)
  const [expanded, setExpanded] = React.useState(false);
  const [template, setTemplate] = useState(false)



  const time = new Date()

  const [campaignName, setCampaignName] = useState(`Session -  ${time.toLocaleString('en-US', { timeZone: 'America/Los_Angeles',hour12: true })}`)

  const [dotsOptions, setDotsOptions] = useState({
    color: dotColour,
    type: "square",
    gradient: {
      type: 'linear',
      colorStops: [{ offset: 0, color: dotColour }, { offset: 1, color: dotGradient }]
    }
  })
  const [cornersSquaresOptions, setCornersSquaresOptions] = useState({
    color: csqColor,
    type: "square"
  })
  const [cornersDotsOptions, setCornersDotsOptions] = useState({
    color: cdColor,
    type: "square"
  })
  const [backgroundOptions, setBackgroundoptions] = useState({
    color: bgcolor
  })
  const [url, setUrl] = useState('https://www.google.com/search?q=remove+all+classes+of+a+div+js&rlz=1C1CHBF_enIN854IN854&sxsrf=ALiCzsYxEI0SZkU1d1a6Mhmkh5LQ_rp25A%3A1658830155997&ei=S73fYp29PIub4t4PsMWFmAg&ved=0ahUKEwjdjsPxp5b5AhWLjdgFHbBiAYMQ4dUDCA4&uact=5&oq=remove+all+classes+of+a+div+js&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEKIEOgcIABBHELADOgQIIRAKSgQIQRgASgQIRhgAUM4GWNobYO4daAFwAXgAgAG6AYgBsQiSAQMwLjaYAQCgAQHIAQjAAQE&sclient=gws-wiz')
  const [target, setTarget] = useState('')
  const [campaign, setCampaign] = useState('')
  const [templateList, setTemplateList] = useState([])

  //get request to get all templates from server
  const getTemplates = async () => {
    const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/gettemplates`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
    })
    const data = await res.json()
    setTemplateList(data.data)
    console.log(data)
  }







  //post request to save as template
  const saveTemplate = async () => {
    setLoading(true)
    const styling = {
      "background": bgcolor,
      "color": {
        "colorEye": csqColor,
        "colorEyeBall": cdColor,
        "colorDot1": dotColour,
        "colorDot2": dotGradient
      },
      "eye": cornersSquaresOptions.type,
      "eyeBall": cornersDotsOptions.type,
      "dp": dotsOptions.type,
      "logo": imgdata,
    }
    var fd = new FormData();
    fd.append('styling', JSON.stringify(styling))
    fd.append('code', campaign)

    const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/saveTemplate`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body:
        fd
    })
    const data = await res.json()
    if (data === 'success') {
      setLoading(false)
      toast.success('Template Saved Successfully')
    }
    else {
      setLoading(false)
      toast.error('Something went wrong')
    }
  }

  //post request to dwelete template
  const deleteTemplate = async (id) => {
    setLoading(true)
    const code = templateList[id].code
    var fd = new FormData();
    fd.append('code', code)
    const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/deleteTemplate`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body:
        fd
    })
    const data = await res.json()
    if (data === 'success') {
      setLoading(false)
      getTemplates()
      toast.success('Template Deleted Successfully')
    }
    else {
      setLoading(false)
      toast.error('Something went wrong')
    }
  }




  //post request to save qr
  const saveQr = async () => {
    // setLoading(true)
    console.log('save qr')
    console.log(custom)
    setLoading(true)
    if (template) {
      saveTemplate()
    }
    console.log('reached')
    console.log(qrCode)
    const rd = await qrCode.getRawData("svg")
    console.log(dotColour)
    const styling = {
      "background": bgcolor,
      "color": {
        "colorEye": csqColor,
        "colorEyeBall": cdColor,
        "colorDot1": dotColour,
        "colorDot2": dotGradient
      },
      "eye": cornersSquaresOptions.type,
      "eyeBall": cornersDotsOptions.type,
      "dp": dotsOptions.type,
      "logo": imgdata
    }

    var dom
    if (custom !== -1) {
      dom = domains[custom].domain
    }
    else if (custom === -1) {
      dom = `${process.env.REACT_APP_VISITOR_URL}`
    }
 

    var fd = new FormData();
    fd.append('furl', url)
    fd.append('dom', dom)
    fd.append('code', campaign)
    fd.append('rd', rd)
    fd.append('styling', JSON.stringify(styling))
    fd.append('type', type)
    fd.append('save', template)
    fd.append('campaignName', campaignName)
    const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/save`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('token')
      },
      body:
        fd,
    })
    console.log(styling)
    const data = await res.json()
    if (data.data === 'saved') {
      toast.success('QR saved successfully')
      window.location.href = '/Url'
    }
    else {
      toast.error('Something went wrong')
    }
    setLoading(false)

    const canvas = document.getElementById('qr-code-side');
    //document.body.appendChild(canvas);

    ;



  }

  useEffect(() => {
    setLoading(true)
    qrCode.append(ref.current)
    setLoading(false)
    // if (hidden)
    //   setDotGradient(dotColour)

  }, [hidden])




  useEffect(() => {
    const getDomains = async () => {
      const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domains`, {
        headers: {
          'x-access-token': localStorage.getItem('token')
        }
      })
      const data = await req.json()
      if (data.status === 'ok') {
        setDomains(data.list)
      }
    }
    getDomains()

  }, [])


  useEffect(() => {
    if (!url) {
      setShow(true)
    }
    if (!validator.isURL(url)) {
      setShow(true)
    }
    qrCode.update({
      data: target,
      image: imgdata,
      dotsOptions: dotsOptions,
      cornersDotOptions: cornersDotsOptions,
      cornersSquareOptions: cornersSquaresOptions,
      backgroundOptions: backgroundOptions
    });
    console.log("dotsOptions")
    console.log(dotsOptions)
  }, [target, imgdata, dotsOptions, cornersDotsOptions, cornersSquaresOptions, backgroundOptions]);
  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };
  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        setImgdata(reader.result);
        console.log(reader.result)
      });
      reader.readAsDataURL(e.target.files[0]);

    }
  }
  const onDotsChange = (props) => {
    const type = dotTypes[props]
    setDotsOptions({ ...dotsOptions, type: type })
  }
  const onEyesChange = (props) => {
    const temp = eyesOptions[props]
    setCornersSquaresOptions({ ...cornersSquaresOptions, type: temp[0] })
    setCornersDotsOptions({ ...cornersDotsOptions, type: temp[1] })
  }

  const onDotsColourChange = (e) => {
    setDotColour(e.target.value)
    const test = { ...dotsOptions }
    test.gradient.colorStops[0].color = e.target.value
    test.gradient.colorStops[1].color = e.target.value
    setDotsOptions(test)

  }

  const onDotGradient = (e) => {
    setDotGradient(e.target.value)
    const test = { ...dotsOptions }
    test.gradient.colorStops[0].color = dotColour
    test.gradient.colorStops[1].color = e.target.value
    setDotsOptions(test)
  }

  const onSquareColorChange = (e) => {
    setCsqColor(e.target.value)
    setCornersSquaresOptions({ ...cornersSquaresOptions, color: e.target.value })
  }

  const onCornerDotColorChange = (e) => {
    setCdColor(e.target.value)
    setCornersDotsOptions({ ...cornersDotsOptions, color: e.target.value })
  }
  const onBgColorChange = (color) => {
    setSketchPickerColor(color.rgb);
    const rbg = `rgba(${color.rgb.r} ${color.rgb.g} ${color.rgb.b} / ${color.rgb.a})`
    console.log(rbg)
    setBgcolor(rbg)
    setBackgroundoptions({ ...backgroundOptions, color: rbg })
  }
  const onSelectedImageChange = (props) => {
    const temp = imageURLS[props]
    setImgdata(temp)
  }

  const generateQr = (e) => {
    e.preventDefault()
    console.log(url)
    if (validator.isURL(url)) {
      const uid = new ShortUniqueId({ length: 10 })
      const code = uid()
      setCampaign(code)
      console.log(code)
      setTarget(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_VISITOR_URL}/${code}`)
      getTemplates()
      setShow(false)
    }
    else
      alert('Enter a valid URL')

  }
  const onFrameClick = () => {
    document.querySelector('.qrbox').classList.remove('qr-bg-col2', 'qr-outer', 'qr-circle')
    document.querySelector('.qrbox').classList.toggle('qr-bg-col')
  }

  const onFrameClick2 = () => {
    document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-outer', 'qr-circle')
    document.querySelector('.qrbox').classList.toggle('qr-bg-col2')
  }
  const onFrameClick3 = () => {
    document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-circle')
    document.querySelector('.qrbox').classList.toggle('qr-outer')
  }

  const onFrameClick4 = () => {
    document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-outer')
    document.querySelector('.qrbox').classList.toggle('qr-circle')
  }
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    if (template)
      saveTemplate()
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };


  const download_png = (image, { name = "img", extension = "png" } = {}) => {
    if (template)
      saveTemplate()
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);  
    a.click();
  };

  const download_svg = (image3, { name = "img", extension = "svg" } = {}) => {
    if (template)
      saveTemplate()
    const a = document.createElement("a");
    console.log("HELLO")
    console.log(image3)
    a.href = image3;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () => takeScreenShot(downloadRef.current).then(download);

  const downloadScreenshot_png = () => takeScreenShot2(downloadRef.current).then(download_png);

  const downloadScreenshot_svg = () => takeScreenShot3(downloadRef.current).then(download_svg);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onTemplateClick = (index) => {
    setHidden(true)
    setDotGradient('')
    const temp = templateList[index]
    setDotColour(temp.styling.color.colorDot1)
    setDotGradient(temp.styling.color.colorDot2)
    setCsqColor(temp.styling.color.colorEye)
    setCdColor(temp.styling.color.colorEyeBall)
    setBgcolor(temp.styling.background)
    setImgdata(temp.styling.logo)
    
    if (temp.styling.color.colorDot2 != "") {
        setHidden(false)
    } 
    
    setDotsOptions({
      color: temp.styling.color.colorDot1,
      type: temp.styling.dp,
      gradient: {
        type: 'linear',
        colorStops: [{ offset: 0, color: temp.styling.color.colorDot1 }, (temp.styling.color.colorDot2 == "") ? { offset: 1, color: temp.styling.color.colorDot1 } : { offset: 1, color: temp.styling.color.colorDot2 }]
      }
    })
    setCornersSquaresOptions({
      color: temp.styling.color.colorEye,
      type: temp.styling.eye
    })
    setCornersDotsOptions({
      color: temp.styling.color.colorEyeBall,
      type: temp.styling.eyeBall
    })
    setBackgroundoptions({
      color: temp.styling.background
    })
  }




  return (
    <>
      <ToastContainer />
      <Loading loading={loading} loaderColor="#f16022" />;
      <Nav />
      <div className="container-fluid" >
        <div className="row">
          <Sidebar />
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 ">
            <div>
              <div className='card url-card'>
                <div className='card-body'>
                  <div className='pane-content'>
                    <div className='form-group'>
                      <div className='d-flex flex-wrap'>
                        <label className='text-bold mr-1'>Enter the URL of your website.</label>
                      </div>
                      <input id="form3Example1c" value={url} onChange={onUrlChange} />

                      <div>
                        <div className='d-flex flex-wrap'>
                          <label className='text-bold mr-1 mt-3'>SmartLink Type</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio3" value="option3" defaultChecked onClick={() => setType('Static')} />
                          <label className="form-check-label" htmlFor="inlineRadio3" onClick={() => setType('Static')} > Static</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input block-add" type="radio" name="inlineRadioOptions1" id="inlineRadio4" value="option4" onClick={() => setType('Dynamic')} />
                          <label className="form-check-label" htmlFor="inlineRadio4" onClick={() => setType('Dynamic')}> Dynamic</label>
                        </div>
                      </div>
                      {/* <div>
                        <div className='d-flex flex-wrap'>
                          <label className='text-bold mr-1'>Select Domain</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio3" value="option5" defaultChecked onClick={() => setType('Static')} />
                          <label className="form-check-label" htmlFor="inlineRadio5" onClick={() => setType('Static')} > Defult</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input block-add" type="radio" name="inlineRadioOptions1" id="inlineRadio6" value="option4" onClick={() => setType('Dynamic')} />
                          <label className="form-check-label" htmlFor="inlineRadio6" onClick={() => setType('Dynamic')}>Custom</label>
                        </div>
                      </div> */}
                      <div className='form-action  group-btn-right'><button id="generate-qr-code" className='btn btn-success btn-qr2 col-sm-12 col-md-4 col-lg-3' onClick={generateQr}>Generate QR code</button></div>
                    </div>

                  </div>
                </div>
              </div>


              <div className='card url-card' hidden={show}>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-7'>

                      <Accordion1 expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faServer} />
                              </div>
                              <div className='title-choose'>Domain Type</div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Domain Type</h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='data-pattern'>
                                      <div>

                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" defaultChecked onClick={() => setShow3(false)} />
                                          <label className="form-check-label" htmlFor="inlineRadio1">default domain (bxr.gg)</label>
                                        </div>    
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={() => setShow3(true)} />
                                          <label className="form-check-label" htmlFor="inlineRadio2">custom domain list</label>
                                        </div>

  
                                      </div>

                                      {show3 ?
                                        <select aria-label="Default select example" className='select-box-type' onChange={(e) => setCustom(e.target.value)} >
                                          <option value="">Select the custom domain</option>
                                          {domains.map((value, index) => (
                                            value.status === 'verified'
                                              ? (<option key={index} value={index} >{value.domain}</option>)
                                              : null

                                          ))}


                                        </select>
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1>


                      <Accordion1 expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faCampground} />
                              </div>
                              <div className='title-choose'>Session Name</div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Session Name</h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='data-pattern'>
                                      <input type="text" className='select-box-type' value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1>




                      <Accordion1 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>

                                <FontAwesomeIcon icon={faQrcode} />
                              </div>
                              <div className='title-choose'>Choose patterns</div>
                            </div></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Data Patterns</h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='data-pattern'>
                                      <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(0)}><img src='./assets/images/qr-code/1.png' alt='text'></img></button>
                                      </div>
                                      <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(1)}><img src='./assets/images/qr-code/2.png' alt='text'></img></button>
                                      </div>
                                      <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(2)}><img src='./assets/images/qr-code/3.png' alt='text'></img></button>
                                      </div>
                                      {/* <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(3)}><img src='./assets/images/qr-code/4.png' alt='text'></img></button>
                                      </div> */}
                                      <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(4)}><img src='./assets/images/qr-code/5.png' alt='text'></img></button>
                                      </div>
                                      <div className="form-check form-check-inline image-radio-btn">

                                        <button className='qr-data eyePattern' onClick={() => onDotsChange(5)}><img src='./assets/images/qr-code/6.png' alt='text'></img></button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1>
                      <Accordion1 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faEye} />
                              </div>
                              <div className='title-choose'>Choose eyes</div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Eyes Patterns</h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='data-pattern'>

                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('sq')}><img src='./assets/images/eyes/1.png' alt='text'></img></button>
                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('sqd')}><img src='./assets/images/eyes/2.png' alt='text'></img></button>
                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('dsq')}><img src='./assets/images/eyes/3.png' alt='text'></img></button>
                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('d')}><img src='./assets/images/eyes/4.png' alt='text'></img></button>
                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('esq')}><img src='./assets/images/eyes/5.png' alt='text'></img></button>
                                      <button className='qr-data eyePattern' onClick={() => onEyesChange('ed')}><img src='./assets/images/eyes/6.png' alt='text'></img></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1 >
                      <Accordion1 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faFileImage} />
                              </div>
                              <div className='title-choose'>Add Logo</div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Add Logo</h4>
                                <div className='logo-content'>
                                  <div className='add-logo'>
                                    <div className='image-preview'>
                                      {imgdata ?
                                        <img src={imgdata} alt='logo'></img> :
                                        <div className='loading-screen'>
                                          <span className='loader'>No logo</span>
                                        </div>
                                      }
                                    </div>
                                    <div>
                                      <div className='form-group block-upload'>
                                        <div className='upload-logo'>
                                          <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="customFile" onChange={onImageChange} alt='Choose'></input>
                                            <label className="custom-file-label" htmlFor="customFile">Choose</label>
                                          </div>
                                        </div>

                                      </div>
                                      <button className='btn btn-default btn-remove' onClick={() => setImgdata(null)}>Remove logo</button>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='media-pattern'>

                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('fb')}><img src='./assets/images/media/1.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tw')}><img src='./assets/images/media/2.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('ig')}><img src='./assets/images/media/3.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wh')}><img src='./assets/images/media/4.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('yt')}><img src='./assets/images/media/5.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('sn')}><img src='./assets/images/media/6.png' alt='text'></img></div>
                                      <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wg')}><img src='./assets/images/media/7.png' alt='text'></img></div>
                                      {/* <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tk')}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`} alt='text'></img></div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1>
                      <Accordion1 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4a-content"
                          id="panel4a-header"
                        >
                          <Typography>
                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faPaintbrush} />
                              </div>
                              <div className='title-choose'>Set Colors</div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Set Colors</h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='form-group mb-0'>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" checked={hidden ? true : false}  defaultChecked onClick={() => setHidden(true)}></input>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Single color</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" onClick={() => setHidden(false)} checked={!hidden ? true : false}  onChange={() => setDotGradient('')}></input>
                                        <label className="form-check-label" htmlFor="inlineRadio2">Color gradient</label>
                                      </div>
                                      {/* <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"></input>
                                              <label className="form-check-label" htmlFor="inlineCheckbox1">Custom Eye Color</label>
                                            </div> */}
                                    </div>
                                  </div>
                                  <div className='col-sm-12'>
                                    <div className='color-title mt-2'>Dots Color</div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='inp-color' >
                                      <input type="color" className='inp-color-box form-cont' value={dotColour} onChange={(e) => onDotsColourChange(e)} />
                                      <input type="text" value={dotColour} readOnly />
                                    </div>
                                  </div>
                                  <div className='col-md-6 '>
                                    <div className='inp-color' hidden={hidden}>
                                      <input type="color" className='inp-color-box' onChange={(e) => onDotGradient(e)} value={dotGradient} />
                                      <input type="text" value={dotGradient} readOnly />
                                    </div>
                                  </div>

                                  <div className='col-sm-12'>
                                    <div className='color-title mt-2'>Eyes Color</div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='inp-color'>
                                      <input type="color" value={csqColor} className='inp-color-box' onChange={e => onSquareColorChange(e)} />
                                      <input type="text" value={csqColor} readOnly />
                                    </div>
                                  </div>
                                  <div className='col-md-6'>
                                    <div className='inp-color' >
                                      <input type="color" value={cdColor} className='inp-color-box' onChange={e => onCornerDotColorChange(e)} />
                                      <input type="text" value={cdColor} readOnly />
                                    </div>
                                  </div>
                                  <div className='col-sm-12'>
                                    <div className='color-title mt-2'>Background Color</div>
                                  </div>
                                  {/* <div className='col-md-6'>
                                    <div className='inp-color'>
                                      <input type="color" value="rbga(255,5,5,0.5)" className='inp-color-box' onChange={e => onBgColorChange(e)} colorformat="rgba" />
                                      <input type="text" value={bgcolor} readOnly />

                                    </div>
                                  </div> */}

                                  <div className='col-md-6'>
                                  <div className="sketchpicker">
                                    <div
                                      style={{
                                        backgroundColor: `rgba(${r} ${g} ${b} / ${a})`,
                                        width: 64,
                                        height: 36,
                                        border: "2px solid white",
                                      }}
                                      onClick={handleClick}
                                    ></div>
                                    { pickerVisibality ?
                                    <div style={{position: 'absolute', zIndex: '9999999999',}}>
                                      <div onClick={handleClickPicker} style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}></div>
                                      <SketchPicker
                                        onChange={(color) => {
                                          onBgColorChange(color);
                                          console.log(color);
                                        }}
                                        color={sketchPickerColor}
                                      /> </div> : null }
                                      
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Typography>
                        </AccordionDetails>
                      </Accordion1>
                      <Accordion1 expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel5a-content"
                          id="panel5a-header"
                        >
                          <Typography>

                            <div className='d-flex align-items-center'>
                              <div className='icon'>
                                <FontAwesomeIcon icon={faBorderAll} />
                              </div>

                              <div className='title-choose'>Choose frame</div>

                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className='accordion-body'>
                              <div className='accordion-warp'>
                                <h4 className='accordion-tltle'>Choose frame </h4>
                                <div className='row'>
                                  <div className='col-md-12'>
                                    <div className='data-pattern'>

                                      <button className='qr-data media-icon-qr' onClick={onFrameClick}><img src='./assets/images/frame/frame1.png' alt='text'></img></button>
                                      <button className='qr-data media-icon-qr' onClick={onFrameClick2}><img src='./assets/images/frame/frame2.png' alt='text'></img></button>
                                      <button className='qr-data media-icon-qr' onClick={onFrameClick3}><img src='./assets/images/frame/frame3.png' alt='text'></img></button>
                                      <button className='qr-data media-icon-qr' onClick={onFrameClick4}><img src='./assets/images/frame/frame4.png' alt='text'></img></button>
                                    </div>

                                  </div>
                                </div>
                                <div className='col-sm-12'>
                                  <div className='color-title mt-2'>Caption</div>
                                </div>
                                <div className='col-md-6'>
                                  <div className='inp-color'>
                                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
                                  </div>
                                </div>


                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion1>

                      <div className='col-md-12'>
                        <div className="form-check d-flex mt-2 ">
                          <input className="form-check-input me-2 save-form-check" type="checkbox" value="" id="form2Example3c" onChange={() => setTemplate(!template)} />
                          <label className="form-check-label" htmlFor="form2Example3" >
                            Save as a template
                          </label>

                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className='download-btn-bar'>
                          <button className='download-btn w-100' onClick={saveQr} >Save this QR</button>
                        </div>
                      </div>

                    </div>

                    <div className='col-md-5'>
                      <div id="qr-code-side" className='qr-code-side' ref={downloadRef}>
                        <div className='qrbox  '>
                          <div className='canvas-title2 '>
                            <h2>{text}</h2>
                          </div>
                          <div ref={ref}></div>
                          <div className='canvas-title '>
                            <h2>{text}</h2>
                          </div>
                          <div className='canvas-title3'>
                            <h2>{text}</h2>
                          </div>
                          <div className='canvas-title4'>
                            <h2>{text}</h2>
                          </div>
                        </div>


                      </div>
                      <div className='m-auto'>
                        <div className='row'>

                          <div className='col-sm-4'>
                            <div className='download-btn-bar'>
                              <button className='download-btn w-100' onClick={downloadScreenshot}><FontAwesomeIcon icon={faDownload} /> JPG</button>
                            </div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='download-btn-bar'>
                              {/* <button className='download-btn w-100' onClick={downloadScreenshot_png}><FontAwesomeIcon icon={faDownload} /> PNG</button> */}

                              <button
                                className='download-btn w-100'
                                onClick={async () => {
                                  console.log("clicked");
                                  if (!downloadRef.current) return;

                                  try {
                                    console.log("clicked2");  
                                    const data = await domToImage.toPng(downloadRef.current);
                                    download_png(data, "produto.png");
                                  } catch (err) {
                                    console.log("Erro");
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faDownload} /> PNG
                              </button>

                            </div>
                          </div>
                          <div className='col-sm-4'>
                            <div className='download-btn-bar'>
                              {/* <button className='download-btn w-100' onClick={downloadScreenshot_svg}><FontAwesomeIcon icon={faDownload} /> SVG</button> */}

                              <button
                                className='download-btn w-100'
                                onClick={async () => {
                                  console.log("clicked");
                                  if (!downloadRef.current) return;
 
                                  try {
                                    console.log("clicked2"); 
                                    const data = await domToImage.toSvg(downloadRef.current);
                                    download_svg(data, "produto.svg");
                                  } catch (err) {
                                    console.log("Erro");
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faDownload} /> SVG
                              </button>

                            </div>




                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='templates-container'>
                      <div className='templates-container-title'>Your Templates</div>
                      <div className='templates-container-images'>
                        {templateList.map((template, index) => (
                          <div className='templates-container-box' key={index}>
                            <div className='templates-box-content'>
                              <button className='template-btn' onClick={() => onTemplateClick(index)}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/Temp_${template.code}.svg`} alt="alt" />
                                <button onClick={() => deleteTemplate(index)} className='template-remove-btn'><FontAwesomeIcon icon={faX} /></button>
                              </button>
                            </div>
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Qr;
