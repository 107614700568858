import React, { useEffect, useState, useRef } from 'react'
import Nav from '../component/inner_pages/Nav';
import Sidebar from '../component/inner_pages/Sidebar';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Url.css'
import './Qr.css';
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { faBorderAll } from '@fortawesome/free-solid-svg-icons'
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons'
import Accordion1 from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useScreenshot, createFileName } from "use-react-screenshot";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRCodeStyling from "qr-code-styling";
import Form from 'react-bootstrap/Form';
import CopyToClipboard from 'react-copy-to-clipboard';
import Switch from 'react-switch'
let qrCode = new QRCodeStyling({
    width: 500,
    height: 500,
    image:
        "",
    imageOptions: {
        crossOrigin: "anonymous",
        imageSize: 0.6,
        margin: 20,
        hideBackgroundDots: false
    }
});
const dotTypes = ['rounded', 'dots', 'classy', 'classy-rounded', 'square', 'extra-rounded']
const eyesOptions = {
    sq: ['square', 'square'],
    sqd: ['square', 'dot'],
    dsq: ['dot', 'square'],
    d: ['dot', 'dot'],
    esq: ['extra-rounded', 'square'],
    ed: ['extra-rounded', 'dot']
}
const imageURLS = {
    fb: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/fb.png`,
    tw: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/tw.png`,
    ig: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/ig.png`,
    wh: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wh.png`,
    yt: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/yt.png`,
    sn: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/sn.png`,
    wg: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wg.png`,
    // tk: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`,
}

function List() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [editUrl, setEditUrl] = useState('')
    const [editCode, setEditCode] = useState('')
    const [editFile, setEditFile] = useState('')
    const [editCampaignName, setEditCampaignName] = useState(`Campaign-${new Date().toJSON().slice(0, 10).replace(/-/g, '/')}`)


    const [show4, setShow4] = useState(false);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = (props) => {
        setShow4(true);
        console.log("aminesh")
        setUrl(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/visit?code=${props}`)
    }



    async function getQr(props) {
        setLoading(true)
        const token = localStorage.getItem('token')
        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/qr`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
                'code': props.file
            },
        })
        if (response) {
            toast.success('fetched')
        }

        console.log(props.surl)
        const data = await response.blob()
        const a = document.createElement("a");
        a.href = URL.createObjectURL(data);
        a.download = "qr.svg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setLoading(false)

    }
    const getQrs = async () => {
        setLoading(true)
        const reqs = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/list`, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        })
        const data = await reqs.json()

        if (data.status === 'ok') {
            if (data.list.length > 0)
                setList(data.list)
        }
        setLoading(false)

    }




    useEffect(() => {
        getQrs()
    }, [])


    const ref = useRef(null);
    const downloadRef = useRef(null)
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const [text, setText] = useState('SCAN ME')
    const [dotColour, setDotColour] = useState('#000000')
    const [csqColor, setCsqColor] = useState('#000000')
    const [cdColor, setCdColor] = useState('#000000')
    const [bgcolor, setBgcolor] = useState('#ffffff')
    const [dotGradient, setDotGradient] = useState("")
    const [imgdata, setImgdata] = useState(null)
    const [hidden, setHidden] = useState(true)
    const [code, setCode] = useState('')
    const [expanded, setExpanded] = React.useState(false);
    const [checked, setChecked] = useState(false)
    const [dotsOptions, setDotsOptions] = useState({
        color: dotColour,
        type: "square",
        gradient: {
            type: 'linear',
            colorStops: [{ offset: 0, color: dotColour }, { offset: 1, color: dotGradient }]
        }
    })
    const [cornersSquaresOptions, setCornersSquaresOptions] = useState({
        color: csqColor,
        type: "square"
    })
    const [cornersDotsOptions, setCornersDotsOptions] = useState({
        color: cdColor,
        type: "square"
    })
    const [backgroundOptions, setBackgroundoptions] = useState({
        color: bgcolor
    })
    const [url, setUrl] = useState('')


    const getQRData = async (props) => {
        setLoading(true)
        setEditUrl(props.furl)
        setEditCode(props.surl)
        setCode(props.userid)
        setEditFile(props.file)
        setShow4(true)
        const token = localStorage.getItem('token')
        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/getqr`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
                'code': props.surl
            },
        })

        const data = await response.json()
        console.log(data)
        setUrl(`${process.env.REACT_APP_SSL_CHECK}://${data.data.domain}:5000/${data.data.code}`)
        setDotColour(data.data.styling.color.colorDot1)
        setDotGradient(data.data.styling.color.colorDot2)
        setCsqColor(data.data.styling.color.colorEye)
        setCdColor(data.data.styling.color.colorEyeBall)
        setBgcolor(data.data.styling.background)
        setDotsOptions({
            color: data.data.styling.color.colorDot1,
            type: data.data.styling.dp,
            gradient: {
                type: 'linear',
                colorStops: [{ offset: 0, color: data.data.styling.color.colorDot1 }, { offset: 1, color: (data.data.styling.color.colorDot2 ? data.data.styling.color.colorDot2 : data.data.styling.color.colorDot1) }]
            }
        })
        setCornersSquaresOptions({
            color: data.data.styling.color.colorEye,
            type: data.data.styling.eye
        })
        setCornersDotsOptions({
            color: data.data.styling.color.colorEyeBall,
            type: data.data.styling.eyeBall
        })
        setBackgroundoptions({
            color: data.data.styling.background
        })
        setLoading(false)
    }

    //post request to save qr styyling
    const saveQrStyling = async () => {
        setLoading(true)
        setShow4(false)
        const styling = {
            "background": bgcolor,
            "color": {
                "colorEye": csqColor,
                "colorEyeBall": cdColor,
                "colorDot1": dotColour,
                "colorDot2": dotGradient
            },
            "eye": cornersSquaresOptions.type,
            "eyeBall": cornersDotsOptions.type,
            "dp": dotsOptions.type,
            "logo": "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
        }
        const rd = await qrCode.getRawData("svg")
        var fd = new FormData()
        fd.append('styling', JSON.stringify(styling))
        fd.append('rd', rd)
        fd.append('userid', code)
        fd.append('url', editUrl)
        fd.append('code', editCode)
        fd.append('file', editFile)
        fd.append('checked', checked)
        fd.append('campaignName', editCampaignName)
        const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/saveqrstyling`, {
            method: 'POST',
            headers: {
                'x-access-token': localStorage.getItem('token')
            },
            body:
                fd,
        })
        const data = await res.json()
        if (data === 'Updated successfully')
            toast.success('QR styling saved successfully')
        setLoading(false)
        document.querySelector(`.qimage-${code}`).src = `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/${code}.svg?t=${Date.now()}`
    }


    useEffect(() => {
        setLoading(true)
        qrCode.append(ref.current)
        setLoading(false)


    }, [url, show4])
    useEffect(() => {

        console.log('qr code re generated')
        qrCode.update({
            data: url,
            image: imgdata,
            dotsOptions: dotsOptions,
            cornersDotOptions: cornersDotsOptions,
            cornersSquareOptions: cornersSquaresOptions,
            backgroundOptions: backgroundOptions
        });
    }, [url, imgdata, dotsOptions, cornersDotsOptions, cornersSquaresOptions, backgroundOptions, show4]);
    const onUrlChange = (event) => {
        event.preventDefault();
        setUrl(event.target.value);
    };
    const onImageChange = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                setImgdata(reader.result);
                console.log(reader.result)
            });
            reader.readAsDataURL(e.target.files[0]);

        }
    }
    const onDotsChange = (props) => {
        const type = dotTypes[props]
        setDotsOptions({ ...dotsOptions, type: type })
    }
    const onEyesChange = (props) => {
        const temp = eyesOptions[props]
        setCornersSquaresOptions({ ...cornersSquaresOptions, type: temp[0] })
        setCornersDotsOptions({ ...cornersDotsOptions, type: temp[1] })
    }

    const onDotsColourChange = (e) => {
        setDotColour(e.target.value)
        const test = { ...dotsOptions }
        test.gradient.colorStops[0].color = e.target.value
        test.gradient.colorStops[1].color = e.target.value
        setDotsOptions(test)

    }
    const onDotGradient = (e) => {
        setDotGradient(e.target.value)
        const test = { ...dotsOptions }
        test.gradient.colorStops[0].color = dotColour
        test.gradient.colorStops[1].color = e.target.value
        setDotsOptions(test)
    }

    const onSquareColorChange = (e) => {
        setCsqColor(e.target.value)
        setCornersSquaresOptions({ ...cornersSquaresOptions, color: e.target.value })
    }

    const onCornerDotColorChange = (e) => {
        setCdColor(e.target.value)
        setCornersDotsOptions({ ...cornersDotsOptions, color: e.target.value })
    }
    const onBgColorChange = (e) => {
        setBgcolor(e.target.value)
        setBackgroundoptions({ ...backgroundOptions, color: e.target.value })
    }
    const onSelectedImageChange = (props) => {
        const temp = imageURLS[props]
        setImgdata(temp)
    }


    const onFrameClick = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col2', 'qr-outer', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-bg-col')
    }

    const onFrameClick2 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-outer', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-bg-col2')
    }
    const onFrameClick3 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-outer')
    }

    const onFrameClick4 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-outer')
        document.querySelector('.qrbox').classList.toggle('qr-circle')
    }
    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(downloadRef.current).then(download);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleSwitchChange = () => {
        setChecked(!checked)
    }

    return (
        <>
            <Loading loading={loading} loaderColor="#f16022" />;
            <ToastContainer />

            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 page-body">

                        <div className="page-hrader">
                            <div className="page-title">
                                <div className='header-title-dashboard'>
                                    <h4 className="h2">Qr Code</h4>
                                </div>
                                <div className="right-btn-view">
                                    <Link to='/Qr'>
                                        <Button variant='primary'  >Create a new QR</Button>
                                    </Link>
                                </div>

                            </div>
                            <Modal dialogClassName="right qr-model-popup" show={show4} onHide={handleClose4}>
                                <Modal.Header closeButton>
                                    <Modal.Title><FontAwesomeIcon icon={faQrcode} /> Edit QR Code</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <div className='col-md-12'>
                                        <div className='qr-code-side' ref={downloadRef}>
                                            <div className='qrbox  '>
                                                <div className='canvas-title2 '>
                                                    <h2>{text}</h2>
                                                </div>
                                                <div ref={ref}></div>
                                                <div className='canvas-title '>
                                                    <h2>{text}</h2>
                                                </div>
                                                <div className='canvas-title3'>
                                                    <h2>{text}</h2>
                                                </div>
                                                <div className='canvas-title4'>
                                                    <h2>{text}</h2>
                                                </div>
                                            </div>


                                        </div>

                                        <div className='form-group'>
                                            <div className='d-flex flex-wrap'>
                                                <label className='text-bold mr-1'>Edit Destination Url</label>
                                            </div>
                                            <input id="form3Example1c" defaultValue={editUrl} onChange={(e) => setEditUrl(e.target.value)} />
                                        </div>
                                        <div className='form-group'>
                                            <div className='d-flex flex-wrap'>
                                                <label className='text-bold mr-1'>Associated Short URL</label>
                                            </div>
                                            <input id="form3Example1c" defaultValue={editCode} onChange={(e) => setEditCode(e.target.value)} readOnly />
                                        </div>
                                    </div>
                                    <div className="switch-btn">
                                        <p className='switch-btn-text'>Track New Session</p>
                                        <Switch onChange={handleSwitchChange} checked={checked} uncheckedIcon={false} checkedIcon={false} handleDiameter={20} width={50} height={24} />
                                    </div>
                                    <div hidden={!checked}>
                                        <span>Session Name</span>
                                        <Form.Control defaultValue={editCampaignName} onChange={(e) => setEditCampaignName(e.target.value)} />
                                    </div>



                                    <div className='row  mt-4'>
                                        <div className='col-md-12'>
                                            <Accordion1 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon'>

                                                                <FontAwesomeIcon icon={faQrcode} />
                                                            </div>
                                                            <div className='title-choose'>Choose patterns</div>
                                                        </div></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div className='accordion-body'>
                                                            <div className='accordion-warp'>
                                                                <h4 className='accordion-tltle'>Data Patterns</h4>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='data-pattern'>
                                                                            <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(0)}><img src='./assets/images/qr-code/1.png' alt='text'></img></button>
                                                                            </div>
                                                                            <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(1)}><img src='./assets/images/qr-code/2.png' alt='text'></img></button>
                                                                            </div>
                                                                            <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(2)}><img src='./assets/images/qr-code/3.png' alt='text'></img></button>
                                                                            </div>
                                                                            {/* <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(3)}><img src='./assets/images/qr-code/4.png' alt='text'></img></button>
                                                                            </div> */}
                                                                            <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(4)}><img src='./assets/images/qr-code/5.png' alt='text'></img></button>
                                                                            </div>
                                                                            <div className="form-check form-check-inline image-radio-btn">

                                                                                <button className='qr-data eyePattern' onClick={() => onDotsChange(5)}><img src='./assets/images/qr-code/6.png' alt='text'></img></button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion1>
                                            <Accordion1 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon'>
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </div>
                                                            <div className='title-choose'>Choose eyes</div>
                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div className='accordion-body'>
                                                            <div className='accordion-warp'>
                                                                <h4 className='accordion-tltle'>Eyes Patterns</h4>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='data-pattern'>

                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('sq')}><img src='./assets/images/eyes/1.png' alt='text'></img></button>
                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('sqd')}><img src='./assets/images/eyes/2.png' alt='text'></img></button>
                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('dsq')}><img src='./assets/images/eyes/3.png' alt='text'></img></button>
                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('d')}><img src='./assets/images/eyes/4.png' alt='text'></img></button>
                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('esq')}><img src='./assets/images/eyes/5.png' alt='text'></img></button>
                                                                            <button className='qr-data eyePattern' onClick={() => onEyesChange('ed')}><img src='./assets/images/eyes/6.png' alt='text'></img></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion1 >
                                            <Accordion1 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel3a-content"
                                                    id="panel3a-header"
                                                >
                                                    <Typography>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon'>
                                                                <FontAwesomeIcon icon={faFileImage} />
                                                            </div>
                                                            <div className='title-choose'>Add Logo</div>
                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div className='accordion-body'>
                                                            <div className='accordion-warp'>
                                                                <h4 className='accordion-tltle'>Add Logo</h4>
                                                                <div className='logo-content'>
                                                                    <div className='add-logo'>
                                                                        <div className='image-preview'>
                                                                            {imgdata ?
                                                                                <img src={imgdata} alt='logo'></img> :
                                                                                <div className='loading-screen'>
                                                                                    <span className='loader'>No logo</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            <div className='form-group block-upload'>
                                                                                <div className='upload-logo'>
                                                                                    <div className="custom-file">
                                                                                        <input type="file" className="custom-file-input" id="customFile" defaultValue="" onChange={onImageChange} alt='Choose'></input>
                                                                                        <label className="custom-file-label" htmlFor="customFile">Choose</label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <button className='btn btn-default btn-remove' onClick={() => setImgdata(null)}>Remove logo</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='media-pattern'>

                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('fb')}><img src='./assets/images/media/1.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tw')}><img src='./assets/images/media/2.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('ig')}><img src='./assets/images/media/3.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wh')}><img src='./assets/images/media/4.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('yt')}><img src='./assets/images/media/5.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('sn')}><img src='./assets/images/media/6.png' alt='text'></img></div>
                                                                            <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wg')}><img src='./assets/images/media/7.png' alt='text'></img></div>
                                                                            {/* <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tk')}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`} alt='text'></img></div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion1>
                                            <Accordion1 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel4a-content"
                                                    id="panel4a-header"
                                                >
                                                    <Typography>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon'>
                                                                <FontAwesomeIcon icon={faPaintbrush} />
                                                            </div>
                                                            <div className='title-choose'>Set Colors</div>
                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div className='accordion-body'>
                                                            <div className='accordion-warp'>
                                                                <h4 className='accordion-tltle'>Set Colors</h4>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='form-group mb-0'>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked onClick={() => setHidden(true)}></input>
                                                                                <label className="form-check-label" htmlFor="inlineRadio1">Single color</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" onClick={() => setHidden(false)}></input>
                                                                                <label className="form-check-label" htmlFor="inlineRadio2">Color gradient</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className='color-title mt-2'>Dots Color</div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className='inp-color' >
                                                                            <input type="color" className='inp-color-box' value={dotColour} onChange={(e) => onDotsColourChange(e)} />
                                                                            <input type="text" value={dotColour} readOnly />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6 '>
                                                                        <div className='inp-color' hidden={hidden}>
                                                                            <input type="color" className='inp-color-box' onChange={(e) => onDotGradient(e)} value={dotGradient} />
                                                                            <input type="text" value={dotGradient} readOnly />
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-sm-12'>
                                                                        <div className='color-title mt-2'>Eyes Color</div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className='inp-color'>
                                                                            <input type="color" value={csqColor} className='inp-color-box' onChange={e => onSquareColorChange(e)} />
                                                                            <input type="text" value={csqColor} readOnly />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className='inp-color' >
                                                                            <input type="color" value={cdColor} className='inp-color-box' onChange={e => onCornerDotColorChange(e)} />
                                                                            <input type="text" value={cdColor} readOnly />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className='color-title mt-2'>Background Color</div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className='inp-color'>
                                                                            <input type="color" value={bgcolor} className='inp-color-box' onChange={e => onBgColorChange(e)} />
                                                                            <input type="text" value={bgcolor} readOnly />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion1>
                                            <Accordion1 expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel5a-content"
                                                    id="panel5a-header"
                                                >
                                                    <Typography>

                                                        <div className='d-flex align-items-center'>
                                                            <div className='icon'>
                                                                <FontAwesomeIcon icon={faBorderAll} />
                                                            </div>

                                                            <div className='title-choose'>Choose frame</div>

                                                        </div>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div className='accordion-body'>
                                                            <div className='accordion-warp'>
                                                                <h4 className='accordion-tltle'>Choose frame </h4>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='data-pattern'>

                                                                            <button className='qr-data media-icon-qr' onClick={onFrameClick}><img src='./assets/images/frame/frame1.png' alt='text'></img></button>
                                                                            <button className='qr-data media-icon-qr' onClick={onFrameClick2}><img src='./assets/images/frame/frame2.png' alt='text'></img></button>
                                                                            <button className='qr-data media-icon-qr' onClick={onFrameClick3}><img src='./assets/images/frame/frame3.png' alt='text'></img></button>
                                                                            <button className='qr-data media-icon-qr' onClick={onFrameClick4}><img src='./assets/images/frame/frame4.png' alt='text'></img></button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Frame Text</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion1>
                                        </div>

                                    </div>


                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            {/* <div className='download-btn-bar'>
                                                <button className='download-btn w-100' onClick={downloadScreenshot}>Download JPG</button>
                                            </div> */}
                                        </div>
                                        <div className='col-sm-12'>
                                            <div className='download-btn-bar'>
                                                <button className='download-btn w-100' onClick={saveQrStyling} >Update QR</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Modal>








                            <div className='card'>
                                <div className='card-body'>
                                    <div className='qr-list'>
                                        <div className="col-md-12">
                                            <div className="doc_list_step">
                                                <div className="doc_list_step_item">
                                                    <h5 className="card-title">Created QR Codes List</h5>
                                                </div>
                                            </div>
                                            <div className="qrlist-container">
                                                <table>
                                                    <tbody>
                                                        {list.map((value, index) => (

                                                            <tr key={index}>
                                                                <td className="qr-code-list-view">
                                                                    <div className="qr-code-list-view-box">
                                                                        <img className={`qimage-${value.surl}`} src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/${value.file}.svg?t=${Date.now()}`} alt="alt" />
                                                                        <div className='qr-code-list-view-box-title'>
                                                                            <h5 className='name'>{value.furl}</h5>
                                                                            <p className='url' style={{ color: '#26695c' }}>{value.domain}:5000/{value.surl}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="action-btn">
                                                                    <div className="action-btn-box">
                                                                        <button className="action-btn-item1" onClick={() => getQr(value)}>Download svg</button>
                                                                        <CopyToClipboard text={`${process.env.REACT_APP_PUBLIC_URL}:5000/${value.surl}`}><button className="action-btn-item-view"><FontAwesomeIcon icon={faCopy} />Copy</button></CopyToClipboard>
                                                                        <Link to={`/statistics?code=${value.surl}`} > <button className="action-btn-item1"><FontAwesomeIcon icon={faEye} />View</button></Link>
                                                                        <button onClick={() => getQRData(value)} className="action-btn-item-view"><FontAwesomeIcon icon={faEdit} />Edit</button>

                                                                    </div>
                                                                </td>

                                                            </tr>



                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </main>
                </div>
            </div >
        </>
    )
}

export default List;
