import React from "react";
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Header from '../component/header/HeaderFive';
import FooterTwo from "../component/footer/FooterTwo";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "react-fullscreen-loading";

function Login() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const checktoken = async () => {
            const token = localStorage.getItem('token')
            if(token){
                history.push('/dashboard')
            }
        }
        checktoken()
    })

    async function loginUser(e) {
        setLoading(true)
		e.preventDefault()

        console.log(process.env)

		const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				password,
			}),
		})

		const data = await response.json()
        
		if (data.status === 'ok') {
			localStorage.setItem('token', data.user)
            localStorage.setItem('ApiKey', data.ApiKey)
            history.push('/dashboard')

			// navigate('/dashboard')
		} else if(data.status === 'wrong credentials') {
			toast('Please check your username and password')
		}
        else if(data.status === 'not found'){
            toast('Please register first')
            history.push('/signup')
        }
		else if(data.status === 'verify') {
			toast('Please verify your account')
		}
        else {
            toast('Server error')
        }
        setLoading(false)
	}
    return (
        <>
        <Loading loading={loading}  loaderColor="#f16022" />;
        <ToastContainer />
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
            <div>
                <div>
                    <section className="signup ptb--90" >
                        {/* style="background-color: #eee; */}
                        <div className="container">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col-lg-12 col-xl-11">
                                    <div className="card text-black" >
                                        {/* style="border-radius: 25px;" */}
                                        <div className="card-body p-md-5">
                                            <div className="row justify-content-center">
                                                <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                                    <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Login</p>

                                                    <form className="mx-1 mx-md-4" onSubmit={loginUser}>

                                                        <div className=" flex-row align-items-center mb-4">
                                                            <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                                            <div className="form-outline flex-fill mb-0">
                                                                <label className="form-label" htmlFor="form3Example1c">Your Email</label>
                                                                <input type="email" id="form3Example1c" required className="form-control" value={email}
                                                                    onChange={(e) => setEmail(e.target.value)} />
                                                            </div>
                                                        </div>


                                                        <div className=" flex-row align-items-center mb-4">
                                                            <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                            <div className="form-outline flex-fill mb-0">
                                                                <label className="form-label" htmlFor="form3Example4c">Password</label>
                                                                <input type="password" id="form3Example4c" className="form-control" value={password}
                                                                    onChange={(e) => setPassword(e.target.value)} required/>

                                                            </div>
                                                        </div>


                                                        <div className="form-check d-flex mb-5">
                                                            <input className="form-check-input me-2 " type="checkbox" value="" id="form2Example3c" />
                                                            <label className="form-check-label" htmlFor="form2Example3">
                                                                Remember me
                                                            </label>
                                                            <a href="/forgot" className="m-auto btn-url">Forgot password?</a>
                                                        </div>

                                                        <div className="d-flex justify-content-center mb-3 mb-lg-4">
                                                            <button type="submit" className="btn btn-primary btn-lg" >Login</button>
                                                        </div>

                                                    </form>

                                                </div>
                                                <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                                    <img src="../assets/images/draw1.jpg"
                                                        className="img-fluid" alt="Sample_image" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterTwo />
        </>
    )

}

export default Login;