import './Dashboard.css'
import Nav from '../component/inner_pages/Nav'
import Sidebar from '../component/inner_pages/Sidebar'
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit, faList } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
function VanitySmartlinks() {
    const [vanityUrls, setVanityUrls] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/vanityurls`, {
            method: 'GET',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 'ok') {
                    setVanityUrls(data.list)
                }

            }
            ).catch(err => console.log(err))
    }, [])

    return (
        <>
            <div >
                <Nav />
                <div className="container-fluid">
                    <div className="row">

                        <Sidebar />
                        <main role="main" className="col-md-12 col-sm-12 ml-sm-auto col-lg-10 main-warp pt--100 page-body">
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='header-title-dashboard'>
                                        <h4 className="h2"> <FontAwesomeIcon icon={faList} /> Vanity Tags</h4>
                                    </div>
                                    <div className='qr-list'>
                                        <div className="class">
                                            <div className="qrlist-container">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Vanity Tags</th>
                                                            <th>Lifetime Clicks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vanityUrls.map((url, index) => {
                                                            return (
                                                                <tr>
                                                                    <td className="qr-code-list-view vanity-view">
                                                                        <div className="qr-code-list-view-box vanity-box">
                                                                            <div className='qr-code-list-view-box-title vainty-title'>
                                                                                <h5 className='name'>{url._id}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="qr-code-list-view vanity-view">
                                                                        <div className="qr-code-list-view-box vanity-box">
                                                                            <div className='qr-code-list-view-box-title vainty-title'>
                                                                                <h5 className='name'>{url.number}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}


export default VanitySmartlinks;
