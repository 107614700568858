import React, { useEffect } from "react";
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import Header from '../component/header/HeaderFive';
import FooterTwo from "../component/footer/FooterTwo";

function Reset() {
    const history = useHistory()
    const location = useLocation()

    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [token, setToken] = useState('')

    useEffect(() => {
        const settoken = async () => {
        const user = queryString.parse(location.search)
        if(!user.token){
            history.goBack()
        }
        else{
        setToken(user.token)}
        }
        settoken()

    })

    async function resetPass(e) {
        e.preventDefault()
        


        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/reset/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password,
            }),
        })

        const data = await response.json()
        // setPassword('')
        // setCpassword('')
        console.log(data)

        if (data.status === 'success') {
            // navigate('/login')
            alert('Successfully changed password')
            history.push('/login')
        }
        else {
            alert('Unknown error has occurred')
        }
    }

    return (
        <>
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

            <div>
                <section className="signup ptb--90" >
                    {/* style="background-color: #eee; */}
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-lg-12 col-xl-11">
                                <div className="card text-black" >
                                    {/* style="border-radius: 25px;" */}
                                    <div className="card-body p-md-5">
                                        <div className="row justify-content-center">
                                            <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Reset Password</p>

                                                <form className="mx-1 mx-md-4" onSubmit={resetPass}>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example4c">Password</label>
                                                            <input type="password" id="form3Example4c" className="form-control" value={password}
                                                                onChange={(e) => setPassword(e.target.value)} required />

                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example4cd">Repeat your password</label>
                                                            <input type="password" id="form3Example4cd" className="form-control" value={cpassword}
                                                                onChange={(e) => setCpassword(e.target.value)} required />

                                                        </div>
                                                    </div>


                                                    <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                        <button type="submit" className="btn btn-primary btn-lg"  >Submit</button>
                                                    </div>


                                                </form>

                                            </div>
                                            <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                                <img src="../assets/images/draw1.jpg"
                                                    className="img-fluid" alt="Sample" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterTwo />
        </>
    )

}
export default Reset;