import React, { useEffect, useState, useRef } from 'react'
import Nav1 from '../component/inner_pages/Nav';
import Sidebar from '../component/inner_pages/Sidebar';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './Url.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faEdit, faDownload } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';
import Switch from 'react-switch'
import QRCodeStyling from "qr-code-styling";
import { faQrcode, faChartLine, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { faBorderAll } from '@fortawesome/free-solid-svg-icons'
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons'
import Accordion1 from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useScreenshot, createFileName } from "use-react-screenshot";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SketchPicker, BlockPicker } from "react-color";
import domToImage from "dom-to-image";
import download from "downloadjs";


let qrCode = new QRCodeStyling({
    width: 500,
    height: 500,
    image:
        "",
    imageOptions: {
        crossOrigin: "anonymous",
        imageSize: 0.6,
        margin: 20,
        hideBackgroundDots: false
    }
});
const dotTypes = ['rounded', 'dots', 'classy', 'classy-rounded', 'square', 'extra-rounded']
const eyesOptions = {
    sq: ['square', 'square'],
    sqd: ['square', 'dot'],
    dsq: ['dot', 'square'],
    d: ['dot', 'dot'],
    esq: ['extra-rounded', 'square'],
    ed: ['extra-rounded', 'dot']
}
const imageURLS = {
    fb: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/fb.png`,
    tw: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/tw.png`,
    ig: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/ig.png`,
    wh: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wh.png`,
    yt: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/yt.png`,
    sn: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/sn.png`,
    wg: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_PUBLIC_URL}/assets/images/media/wg.png`,
    // tk: `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`,
}




function Url() {

    const renderTooltip = props => (
        <Tooltip {...props}>Copy</Tooltip>
    );

    const renderTooltip_Metrics = props => (
        <Tooltip {...props}>Metrics</Tooltip>
    );

    const renderTooltip_qrcode = props => (
        <Tooltip {...props}>Edit QR Code</Tooltip>
    );

    const renderTooltip_link = props => (
        <Tooltip {...props}>Edit SmartLink</Tooltip>
    );

    const renderTooltip_transh = props => (
        <Tooltip {...props}>Delete</Tooltip>
    );

    const [sketchPickerColor, setSketchPickerColor] = useState({
        r: "241",
        g: "112",
        b: "19",
        a: "0.3",
    });
    // destructuring rgba from state
    const { r, g, b, a } = sketchPickerColor;

    //creating state to store our color and also set color using onChange event for block picker
    const [pickerVisibality, setpickerVisibality] = useState(false);

    const handleClick = () => {
        setpickerVisibality(!pickerVisibality);
    }


    const handleClickPicker = () => {
        if (pickerVisibality) {
            setpickerVisibality(false);
        }

    }


    const history = useHistory()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [list, setList] = useState([])
    const [domains, setDomains] = useState([])
    const [newurl, setNewurl] = useState('')
    const [newtitle, setTitle] = useState('')

    const [index, setIndex] = useState(-1)
    const [custom, setCustom] = useState(-1)
    const [type, setType] = useState('Static')
    const [editUrl, setEditUrl] = useState('')
    const [editTitle, setEditTitle] = useState('')
    const [editDomain, setEditDomain] = useState('')
    const [editIndex, setEditIndex] = useState(-1)
    const [urlid, setUrlid] = useState('')
    const [fileid, setFileid] = useState('')
    const [loading, setLoading] = useState(false)
    const [editShow, setEditShow] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [utm1, setUtm1] = useState('')
    const [utm2, setUtm2] = useState('')
    const [utm3, setUtm3] = useState('')
    const time = new Date()
    const [campaignName, setCampaignName] = useState(`Session -  ${time.toLocaleString('en-US', { timeZone: 'America/Los_Angeles',hour12: true }) }`)
    const [editCampaignName, setEditCampaignName] = useState(`Session -  ${time.toLocaleString('en-US', { timeZone: 'America/Los_Angeles',hour12: true }) }`)
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);  
    const ref = useRef(null);
    const downloadRef = useRef(null)
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });
    const [text, setText] = useState('SCAN ME')
    const [dotColour, setDotColour] = useState('#000000')
    const [csqColor, setCsqColor] = useState('#000000')
    const [cdColor, setCdColor] = useState('#000000')
    const [bgcolor, setBgcolor] = useState('#ffffff')
    const [dotGradient, setDotGradient] = useState("")
    const [imgdata, setImgdata] = useState(null)
    const [hidden, setHidden] = useState(true)
    const [code, setCode] = useState('')
    const [edittype, setEdittype] = useState('')
    const [expanded, setExpanded] = React.useState(false);
    const [dotsOptions, setDotsOptions] = useState({
        color: dotColour,
        type: "square",
        gradient: {
            type: 'linear',
            colorStops: [{ offset: 0, color: dotColour }, { offset: 1, color: dotGradient }]
        }
    })
    const [cornersSquaresOptions, setCornersSquaresOptions] = useState({
        color: csqColor,
        type: "square"
    })
    const [cornersDotsOptions, setCornersDotsOptions] = useState({
        color: cdColor,
        type: "square"
    })
    const [backgroundOptions, setBackgroundoptions] = useState({
        color: bgcolor
    })
    const [url, setUrl] = useState('')

    const [baseDoamin, setBaseDoamin] = useState('')



    const handleClose4 = () => setShow4(false);
    const handleClose_qr = () => setShow5(false);
    const handleClose_url = () => setShow6(false);
    const getUrls = async () => {
        const reqs = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/list`, {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        })
        const data = await reqs.json()
        if (data.status === 'ok') {
            setList(data.list)
        }
    }

    useEffect(() => {
        const getDomains = async () => {
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domains`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })
            const data = await req.json()
            if (data.status === 'ok') {
                setDomains(data.list)
            }
        }
        getDomains()
        getUrls()
    }, [])


    const handleButton = () => {
        setShow(true)
        setShow3(false)
    }

    async function getQr(props) {
        setLoading(true)
        const token = localStorage.getItem('token')
        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/qr`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
                'code': props.file
            },
        })
        if (response) {
            toast.success('fetched')
        }
        const data = await response.blob()
        const a = document.createElement("a");
        a.href = URL.createObjectURL(data);
        a.download = "qr.svg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false)
    }



    async function downloadQr(file) {
        setLoading(true)
        const token = localStorage.getItem('token')
        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/qr`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
                'code': file
            },
        })
        if (response) {
            toast.success('fetched')
        }

        const data = await response.blob()
        const a = document.createElement("a");
        a.href = URL.createObjectURL(data);
        a.download = "qr.svg";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setLoading(false)

    }

    async function create(e) {
        e.preventDefault()
        var dom
        if (custom !== -1) {
            dom = domains[custom].domain
        }
        else if (custom === -1) {
            dom = `${process.env.REACT_APP_VISITOR_URL}`
        }


        var fd = new FormData()
        fd.append('newurl', newurl)
        fd.append('title', newtitle)
        fd.append('dom', dom)
        fd.append('type', type)
        fd.append('name', campaignName)
        fd.append('utm', checked2)
        fd.append('utm1', utm1)
        fd.append('utm2', utm2)
        fd.append('utm3', utm3)

        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/short`, {
            method: 'POST',
            headers: {
                'x-access-token': localStorage.getItem('token')
            },
            body:
                fd,
        })
        const data = await req.json()

        if (data.status === 'created successfully') {
            getUrls()
            alert('Created Successfully')
        }
        else if (data === 'already exists') {
            alert('You have already created a short url for this.')
            history.push('/Url')
        }
        else if (data === 'token expired') {
            localStorage.clear()
            history.push('/login')
        }
        else {
            alert('Some error has occurred')
        }
        setShow(false)
    }

    async function deleteUrl(index) {
        const code = (list[index].surl)
        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/delete/${code}`, {
            method: 'DELETE',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        })

        const data = await req.json()
        if (data === 'deleted') {
            setList(list.filter(item => item.index !== index))
            alert('Deleted successfully')

        }
        else {
            alert('Some error has occurred')
        }
        setShow2(false)
        getUrls()

    }
    function onclick(index) {
        setShow2(true)
        setIndex(index)
    }
    const handleRadio = (props) => {
        setType(props)
    }

    async function changeUrl(props) {
        setLoading(true)

        const code = (list[editIndex].surl)
        const file = (list[editIndex].file)
        const campaign = list[editIndex].campaign
        const styling = {
            "background": bgcolor,
            "color": {
                "colorEye": csqColor,
                "colorEyeBall": cdColor,
                "colorDot1": dotColour,
                "colorDot2": dotGradient
            },
            "eye": cornersSquaresOptions.type,
            "eyeBall": cornersDotsOptions.type,
            "dp": dotsOptions.type,
            "logo": imgdata
        }
        const rd = await qrCode.getRawData("svg")

        var fd = new FormData()
        fd.append('editUrl', editUrl)
        fd.append('edittype', edittype)  
        fd.append('editDomain', editDomain)
        fd.append('title', editTitle)
        fd.append('urlid', urlid)
        fd.append('checked', checked)
        fd.append('editCampaignName', editCampaignName)
        fd.append('styling', JSON.stringify(styling))
        fd.append('file', file)
        fd.append('rd', rd)
        fd.append('imgdata', imgdata)
        
        fd.append('currentCampaign', campaign)

        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/edit/${code}`, {
            method: 'PUT',
            headers: {
                'x-access-token': localStorage.getItem('token')
            },
            body:
                fd,
        })

        const data = await req.json()
        if (data === 'Sorry that backhalf is already taken, please choose something else') {
            toast.error(data)
        }
        else if (data === 'Updated successfully') {
            toast.success('Updated successfully')
            setEditShow(false)
            setShow4(false)
        }

        getUrls()
        setLoading(false)


    }


    const handleEditClick = (props) => {

        setEditShow(true)
        setEditUrl(list[props].furl)
        setEditTitle(list[props].title)
        setEditDomain(list[props].surl)
        setUrlid(list[props].urlid)
        setEditIndex(props)
        setShow4(true)
        const tcode = list[props].surl
        const tdom = list[props].domain
        const styling = list[props].styling

        setUrl(`${process.env.REACT_APP_SSL_CHECK}://${tdom}/${tcode}`)
        setDotColour(styling.color.colorDot1)
        setDotGradient(styling.color.colorDot2)
        setCsqColor(styling.color.colorEye)
        setCdColor(styling.color.colorEyeBall)
        setBgcolor(styling.background)
        setDotsOptions({
            color: styling.color.colorDot1,
            type: styling.dp,
            gradient: {
                type: 'linear',
                colorStops: [{ offset: 0, color: styling.color.colorDot1 }, { offset: 1, color: (styling.color.colorDot2 ? styling.color.colorDot2 : styling.color.colorDot1) }]
            }
        })
        setCornersSquaresOptions({
            color: styling.color.colorEye,
            type: styling.eye
        })
        setCornersDotsOptions({
            color: styling.color.colorEyeBall,
            type: styling.eyeBall
        })
        setBackgroundoptions({
            color: styling.background
        })
    }






    const handleEditClick_qr = (props) => {
        setEdittype('qr')
        setHidden(true)
        console.log(time)
        setEditUrl(list[props].furl)
        setEditTitle(list[props].title)
        setEditDomain(list[props].surl)
        setUrlid(list[props].urlid)
        setFileid(list[props].file)
        setEditIndex(props)
        setShow5(true)
        const tcode = list[props].surl
        const tdom = list[props].domain
        const styling = list[props].styling
        if (styling.color.colorDot2 != "") {
            setHidden(false)
        }
        setUrl(`${process.env.REACT_APP_SSL_CHECK}://${tdom}/${tcode}`)
        setDotColour(styling.color.colorDot1)
        setDotGradient(styling.color.colorDot2)
        setCsqColor(styling.color.colorEye)
        setImgdata(styling.logo)
        setCdColor(styling.color.colorEyeBall)
        setBgcolor(styling.background)
        setDotsOptions({
            color: styling.color.colorDot1,
            type: styling.dp,
            gradient: {
                type: 'linear',
                colorStops: [{ offset: 0, color: styling.color.colorDot1 }, { offset: 1, color: (styling.color.colorDot2 ? styling.color.colorDot2 : styling.color.colorDot1) }]
            }
        })
        setCornersSquaresOptions({
            color: styling.color.colorEye,
            type: styling.eye
        })
        setCornersDotsOptions({
            color: styling.color.colorEyeBall,
            type: styling.eyeBall
        })
        setBackgroundoptions({
            color: styling.background
        })
        setEditShow(true)
    }

    const handleEditClick_url = (props) => {
 
        setType(list[props].type)
        console.log("hello"+type)
        setEdittype('url')
        setEditShow(true)
        setEditUrl(list[props].furl)
        setEditTitle(list[props].title)
        setEditDomain(list[props].surl)
        setUrlid(list[props].urlid)
        setEditIndex(props)
        setTitle(list[props].title)
        setShow6(true)
        const tcode = list[props].surl
        const tdom = list[props].domain
        const styling = list[props].styling

        setUrl(`${process.env.REACT_APP_SSL_CHECK}://${tdom}/${tcode}`)
        setBaseDoamin(`${process.env.REACT_APP_SSL_CHECK}://${tdom}/`)
        setDotColour(styling.color.colorDot1)
        setDotGradient(styling.color.colorDot2)
        setCsqColor(styling.color.colorEye)
        setCdColor(styling.color.colorEyeBall)
        setBgcolor(styling.background)
        setImgdata(styling.logo)
        setDotsOptions({
            color: styling.color.colorDot1,
            type: styling.dp,
            gradient: {
                type: 'linear',
                colorStops: [{ offset: 0, color: styling.color.colorDot1 }, { offset: 1, color: (styling.color.colorDot2 ? styling.color.colorDot2 : styling.color.colorDot1) }]
            }
        })
        setCornersSquaresOptions({
            color: styling.color.colorEye,
            type: styling.eye
        })
        setCornersDotsOptions({
            color: styling.color.colorEyeBall,
            type: styling.eyeBall
        })
        setBackgroundoptions({
            color: styling.background
        })
    }

    const handleSwitchChange = () => {
        setChecked(!checked)
    }
    const handleSwitchChange2 = () => {
        setChecked2(!checked2)
    }

    useEffect(() => {
        setLoading(true)
        qrCode.append(ref.current)
        setLoading(false)


    }, [url, show4])
    useEffect(() => {


        const newshort = `${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_VISITOR_URL}/${editDomain}`
        qrCode.update({
            data: newshort,
            image: imgdata,
            dotsOptions: dotsOptions,
            cornersDotOptions: cornersDotsOptions,
            cornersSquareOptions: cornersSquaresOptions,
            backgroundOptions: backgroundOptions
        });

        const canvasobj = document.getElementsByTagName("canvas")[0];
        if (canvasobj != undefined) {
            const ctx = canvasobj.getContext('2d');
            ctx.restore();

        }
    }, [editDomain, url, imgdata, dotsOptions, cornersDotsOptions, cornersSquaresOptions, backgroundOptions]);
    const onUrlChange = (event) => {
        event.preventDefault();
        setUrl(event.target.value);
    };
    const onImageChange = (e) => {
        if (e.target.files[0]) {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                setImgdata(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);

        }
    }
    const onDotsChange = (props) => {
        const type = dotTypes[props]
        setDotsOptions({ ...dotsOptions, type: type })
    }
    const onEyesChange = (props) => {
        const temp = eyesOptions[props]
        setCornersSquaresOptions({ ...cornersSquaresOptions, type: temp[0] })
        setCornersDotsOptions({ ...cornersDotsOptions, type: temp[1] })
    }

    const onDotsColourChange = (e) => {
        setDotColour(e.target.value)
        const test = { ...dotsOptions }
        test.gradient.colorStops[0].color = e.target.value
        test.gradient.colorStops[1].color = e.target.value
        setDotsOptions(test)

    }
    const onDotGradient = (e) => {
        setDotGradient(e.target.value)
        const test = { ...dotsOptions }
        test.gradient.colorStops[0].color = dotColour
        test.gradient.colorStops[1].color = e.target.value
        setDotsOptions(test)
    }

    const onSquareColorChange = (e) => {
        setCsqColor(e.target.value)
        setCornersSquaresOptions({ ...cornersSquaresOptions, color: e.target.value })
    }

    const onCornerDotColorChange = (e) => {
        setCdColor(e.target.value)
        setCornersDotsOptions({ ...cornersDotsOptions, color: e.target.value })
    }
    // const onBgColorChange = (e) => {
    //     setBgcolor(e.target.value)
    //     setBackgroundoptions({ ...backgroundOptions, color: e.target.value })
    // }
    const onSelectedImageChange = (props) => {
        const temp = imageURLS[props]
        setImgdata(temp)
    }


    const onBgColorChange = (color) => {
        setSketchPickerColor(color.rgb);
        const rbg = `rgba(${color.rgb.r} ${color.rgb.g} ${color.rgb.b} / ${color.rgb.a})`
        console.log(rbg)
        setBgcolor(rbg)
        setBackgroundoptions({ ...backgroundOptions, color: rbg })
    }


    const onFrameClick = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col2', 'qr-outer', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-bg-col')
    }

    const onFrameClick2 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-outer', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-bg-col2')
    }
    const onFrameClick3 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-circle')
        document.querySelector('.qrbox').classList.toggle('qr-outer')
    }

    const onFrameClick4 = () => {
        document.querySelector('.qrbox').classList.remove('qr-bg-col', 'qr-bg-col2', 'qr-outer')
        document.querySelector('.qrbox').classList.toggle('qr-circle')
    }
    const download = (image, { name = "img", extension = "jpg" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const download_png = (image, { name = "img", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(downloadRef.current).then(download);

    const downloadScreenshot_png = () => takeScreenShot(downloadRef.current).then(download_png);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <>
            <ToastContainer />
            <Loading loading={loading} loaderColor="#f16022" />;
            <Nav1 />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 page-body">
                        <Modal dialogClassName="right" show={show} onHide={() => setShow(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title> <FontAwesomeIcon icon={faLink} /> Create SmartLink</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Destination Url</Form.Label>
                                        <Form.Control as="textarea" rows={5} required onChange={(e) => setNewurl(e.target.value)} />
                                    </Form.Group>
                                </Form>

                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Enter Title/Description</Form.Label>
                                        <Form.Control as="textarea" rows={5} required onChange={(e) => setTitle(e.target.value)} />
                                    </Form.Group>
                                </Form>
                                <Form.Group>
                                    <Form.Label>SmartLink Type</Form.Label>
                                    <div className='view-modal-sec'>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" value="option1" checked />
                                            <label className="form-check-label" htmlFor="inlineRadio1" id="inlineRadio1" onClick={() => handleRadio('Static')} >Static</label>
                                        </div>
                                        <div className="inline-hover-btn-static">
                                            <div className='hover-btn-view' href="" rel="noreferrer"> <img alt="snack-bar-icon" src="./assets/images/media/info.svg" /> </div>
                                        </div>

                                        <div className="hover-section">
                                            <div className="hover-section-content">
                                                <p className='mb-0'>This smartlink will go to one domain destination and can not be re-directed.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <Form.Group>
                                    
                                    <div className="mt-3 view-modal-sec">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input block-add" type="radio" name="inlineRadioOptions1" id="inlineRadio2" value="option2" onClick={() => handleRadio('Dynamic')} />
                                            <label className="form-check-label" htmlFor="inlineRadio2" onClick={() => handleRadio('Dynamic')} >Dynamic</label>
                                        </div>
                                        <div className="inline-hover-btn-dynamic">
                                            <div className='hover-btn-view' href="" rel="noreferrer"> <img alt="snack-bar-icon" src="./assets/images/media/info.svg" /> </div>
                                        </div>
                                        <div className="hover-section-dynamic">
                                            <div className="hover-section-content">
                                                <p className='mb-0'>You can re-direct this smartlink to new destination as you wish, over and over. Note: There may be limits based on your plan</p>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                {/* <div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio3" value="option3" defaultChecked onClick={() => handleRadio('Static')} />
                                        <label className="form-check-label" htmlFor="inlineRadio3" onClick={() => handleRadio('Static')} > Static</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input block-add" type="radio" name="inlineRadioOptions1" id="inlineRadio4" value="option4" onClick={() => handleRadio('Dynamic')} />
                                        <label className="form-check-label" htmlFor="inlineRadio4" onClick={() => handleRadio('Dynamic')}> Dynamic</label>
                                    </div>
                                </div> */}
                                <Form.Group>
                                    <Form.Label>Domain Type</Form.Label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions3" id="inlineRadio3" value="option3" defaultChecked onClick={() => setShow3(false)} />
                                            <label className="form-check-label" htmlFor="inlineRadio3" onClick={() => setShow3(false)}>default domain (bxr.gg)</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input block-add" type="radio" name="inlineRadioOptions3" id="inlineRadio4" value="option4" onClick={() => setShow3(true)} />
                                            <label className="form-check-label" htmlFor="inlineRadio4" onClick={() => setShow3(true)} >custom domain list</label>
                                        </div>
                                    </div>
                                </Form.Group>

                                {show3 ?
                                    <select aria-label="Default select example mb-3" onChange={(e) => setCustom(e.target.value)} >
                                        <option value="">Select from your Custom Domains</option>
                                        {domains.map((value, index) => (
                                            value.status === 'verified'
                                                ? (<option key={index} value={index} >{value.domain}</option>)
                                                : null

                                        ))}


                                    </select>
                                    : null}
                                <Form.Label>Session Name</Form.Label>
                                <Form.Control defaultValue={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
                                <div className="switch-btn">
                                    <p className='switch-btn-text'>Append UTM Paremeters?</p>
                                    <Switch onChange={handleSwitchChange2} checked={checked2} uncheckedIcon={false} checkedIcon={false} handleDiameter={20} width={50} height={24} />
                                </div>
                                <div hidden={!checked2}>
                                    <div className="modal-inner-content" >

                                        <p style={{ fontSize: '15px' }}>UTMs can help you track web traffic in analytics tools.</p>
                                    </div>



                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Source</Form.Label>
                                        <Form.Control as="textarea" rows={3} required onChange={(e) => setUtm1(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Medium</Form.Label>
                                        <Form.Control as="textarea" rows={3} required onChange={(e) => setUtm2(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Session</Form.Label>
                                        <Form.Control as="textarea" rows={3} required onChange={(e) => setUtm3(e.target.value)} />
                                    </Form.Group>

                                    <div className="utm-inputs-snack-bar">
                                        <div className="orb-snack-bar info">
                                            <img alt="snack-bar-icon" src="./assets/images/media/info.svg" />
                                            <div className="message small">If you use UTMs, be sure to complete all 3 fields.</div>
                                        </div>
                                    </div>
                                </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <div className='footer-modal'>
                                    <div>
                                        <Button variant="btn btn-secondary mr-2" onClick={() => setShow(false)}>
                                            Close
                                        </Button>
                                        <Button variant="btn btn-primary" onClick={create}>
                                            Create
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>

                        {/* qrcode edit section */}
                        <Modal dialogClassName="right qr-model-popup" show={show5} onHide={handleClose_qr}>
                            <Modal.Header closeButton>
                                <Modal.Title> <FontAwesomeIcon icon={faQrcode} /> Edit QR Styling</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='col-md-12'>
                                    <div className='qr-code-side' ref={downloadRef}>
                                        <div className='qrbox  '>
                                            <div className='canvas-title2 '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div ref={ref}></div>
                                            <div className='canvas-title '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title3'>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title4'>
                                                <h2>{text}</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group' style={{ display: "none" }}>
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Edit Destination Url</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editUrl} onChange={(e) => setEditUrl(e.target.value)} />
                                    </div>
                                    <div className='form-group' style={{ display: "none" }}>
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Update Vanity Tag</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editDomain} onChange={(e) => setEditDomain(e.target.value)} />
                                    </div>

                                    <div className="switch-btn" style={{ display: "none" }}>
                                        <p className='switch-btn-text'>Track New Session</p>
                                        <Switch onChange={handleSwitchChange} checked={checked} uncheckedIcon={false} checkedIcon={false} handleDiameter={20} width={50} height={24} />
                                    </div>
                                    <div hidden={!checked}>
                                        <span>Session Name</span>
                                        <Form.Control defaultValue={editCampaignName} onChange={(e) => setEditCampaignName(e.target.value)} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={downloadScreenshot}><FontAwesomeIcon icon={faDownload} /> JPG</button>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='download-btn-bar'>
                                            {/* <button className='download-btn w-100' onClick={downloadScreenshot_png}><FontAwesomeIcon icon={faDownload} /> PNG</button> */}
                                        
                                            <button
                                                className='download-btn w-100'
                                                onClick={async () => {
                                                    console.log("clicked");
                                                    if (!downloadRef.current) return;

                                                    try {
                                                        console.log("clicked2");
                                                        const data = await domToImage.toPng(downloadRef.current);
                                                        download_png(data, "produto.png");
                                                    } catch (err) {
                                                        console.log("Erro");
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faDownload} /> PNG
                                            </button>

                                        </div>
                                    </div>

                                    <div className='col-sm-4'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={() => downloadQr(fileid)}><FontAwesomeIcon icon={faDownload} /> SVG</button>
                                        </div>
                                    </div>
                                </div>


                                <div className='row  mt-4'>
                                    <div className='col-md-12'>
                                        <Accordion1 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>

                                                            <FontAwesomeIcon icon={faQrcode} />
                                                        </div>
                                                        <div className='title-choose'>Choose patterns</div>
                                                    </div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Data Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(0)}><img src='./assets/images/qr-code/1.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(1)}><img src='./assets/images/qr-code/2.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(2)}><img src='./assets/images/qr-code/3.png' alt='text'></img></button>
                                                                        </div>
                                                                        {/* <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(3)}><img src='./assets/images/qr-code/4.png' alt='text'></img></button>
                                                                        </div> */}
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(4)}><img src='./assets/images/qr-code/5.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(5)}><img src='./assets/images/qr-code/6.png' alt='text'></img></button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </div>
                                                        <div className='title-choose'>Choose eyes</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Eyes Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sq')}><img src='./assets/images/eyes/1.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sqd')}><img src='./assets/images/eyes/2.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('dsq')}><img src='./assets/images/eyes/3.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('d')}><img src='./assets/images/eyes/4.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('esq')}><img src='./assets/images/eyes/5.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('ed')}><img src='./assets/images/eyes/6.png' alt='text'></img></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1 >
                                        <Accordion1 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faFileImage} />
                                                        </div>
                                                        <div className='title-choose'>Add Logo</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Add Logo</h4>
                                                            <div className='logo-content'>
                                                                <div className='add-logo'>
                                                                    <div className='image-preview'>
                                                                        {imgdata ?
                                                                            <img src={imgdata} alt='logo'></img> :
                                                                            <div className='loading-screen'>
                                                                                <span className='loader'>No logo</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div className='form-group block-upload'>
                                                                            <div className='upload-logo'>
                                                                                <div className="custom-file">
                                                                                    <input type="file" className="custom-file-input" id="customFile" defaultValue="" onChange={onImageChange} alt='Choose'></input>
                                                                                    <label className="custom-file-label" htmlFor="customFile">Choose</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <button className='btn btn-default btn-remove' onClick={() => setImgdata(null)}>Remove logo</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='media-pattern'>

                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('fb')}><img src='./assets/images/media/1.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tw')}><img src='./assets/images/media/2.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('ig')}><img src='./assets/images/media/3.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wh')}><img src='./assets/images/media/4.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('yt')}><img src='./assets/images/media/5.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('sn')}><img src='./assets/images/media/6.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wg')}><img src='./assets/images/media/7.png' alt='text'></img></div>
                                                                        {/* <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tk')}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`} alt='text'></img></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faPaintbrush} />
                                                        </div>
                                                        <div className='title-choose'>Set Colors</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Set Colors</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='form-group mb-0'>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11" defaultValue="option1" checked={hidden ? true : false} defaultChecked onClick={() => setHidden(true)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio11">Single color</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" checked={!hidden ? true : false} onClick={() => setHidden(false)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio2">Color gradient</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Dots Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" className='inp-color-box' value={dotColour} onChange={(e) => onDotsColourChange(e)} />
                                                                        <input type="text" value={dotColour} readOnly />
                                                                    </div>
                                                                </div>


                                                                {!hidden &&
                                                                    <div className='col-md-6 '>
                                                                        <div className='inp-color'>
                                                                            <input type="color" className='inp-color-box' onChange={(e) => onDotGradient(e)} value={dotGradient} />
                                                                            <input type="text" value={dotGradient} readOnly />
                                                                        </div>
                                                                    </div>}

                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Eyes Color</div>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={csqColor} className='inp-color-box' onChange={e => onSquareColorChange(e)} />
                                                                        <input type="text" value={csqColor} readOnly />
                                                                    </div>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" value={cdColor} className='inp-color-box' onChange={e => onCornerDotColorChange(e)} />
                                                                        <input type="text" value={cdColor} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Background Color</div>
                                                                </div>
                                                                {/* <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={bgcolor} className='inp-color-box' onChange={e => onBgColorChange(e)} />
                                                                        <input type="text" value={bgcolor} readOnly />
                                                                    </div>
                                                                </div> */}

                                                                <div className='col-md-12'>
                                                                    <div className="sketchpicker">
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: `rgba(${r} ${g} ${b} / ${a})`,
                                                                                width: 64,
                                                                                height: 36,
                                                                                border: "2px solid white",
                                                                            }}
                                                                            onClick={handleClick}
                                                                        ></div>
                                                                        {pickerVisibality ?
                                                                            <div style={{ position: 'absolute', zIndex: '9999999999', }}>
                                                                                <div onClick={handleClickPicker} style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}></div>
                                                                                <SketchPicker
                                                                                    onChange={(color) => {
                                                                                        onBgColorChange(color);
                                                                                        console.log(color);
                                                                                    }}
                                                                                    color={sketchPickerColor}
                                                                                /> </div> : null}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel5a-content"
                                                id="panel5a-header"
                                            >
                                                <Typography>

                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faBorderAll} />
                                                        </div>

                                                        <div className='title-choose'>Choose frame</div>

                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Choose frame </h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick}><img src='./assets/images/frame/frame1.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick2}><img src='./assets/images/frame/frame2.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick3}><img src='./assets/images/frame/frame3.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick4}><img src='./assets/images/frame/frame4.png' alt='text'></img></button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='col-sm-12'>
                                                                <div className='color-title mt-2'>Frame Text</div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='inp-color'>
                                                                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                    </div>

                                </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <div className='row'>


                                    <div className='col-sm-12'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={() => changeUrl(index)}  >Update QR</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {/* qrcode edit section */}

                        {/* URL edit section */}

                        <Modal dialogClassName="right qr-model-popup" show={show6} onHide={handleClose_url}>
                            <Modal.Header closeButton>
                                <Modal.Title> <FontAwesomeIcon icon={faLink} /> Edit SmartLink</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='col-md-12'>
                                    {/* <div className='qr-code-side' ref={downloadRef}>
                                        <div className='qrbox  '>
                                            <div className='canvas-title2 '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div ref={ref}></div>
                                            <div className='canvas-title '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title3'>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title4'>
                                                <h2>{text}</h2>
                                            </div>
                                        </div>


                                    </div> */}

                                    <div className='form-group' >
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Edit Destination URL</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editUrl} onChange={(e) => setEditUrl(e.target.value)} />
                                    </div>

                                    <div className='form-group' >
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Title / Description</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
                                    </div>

                                    {/* <div className='form-group' >
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Update Vanity BackHalf</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editDomain} onChange={(e) => setEditDomain(e.target.value)} />
                                    </div> */}
                                   
                                    {type == 'Dynamic' ?
                                        

                                        <Form.Group>
                                            <label id="input-field--label" class="input-field--label">Update Vanity Tag</label>
                                            <div class="input-field--CUSTOM  error ">
                                                <div className='d-flex'>
                                                    <div class="input-field--static-value" style={{ color: 'blue' }}>{baseDoamin}</div>
                                                    <div class="input-field--input-wrapper"><input aria-labelledby="input-field--label" type="text" class="input-field--input" autocomplete="false" tabindex="0" defaultValue={editDomain} onChange={(e) => setEditDomain(e.target.value)} data-gtm-form-interact-field-id="1" /></div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        : <Form.Group style={{display:'none'}}>
                                            <label id="input-field--label" class="input-field--label">Update Vanity Tag</label>
                                            <div class="input-field--CUSTOM  error ">
                                                <div className='d-flex'>
                                                    <div class="input-field--static-value" style={{ color: 'blue' }}>{baseDoamin}</div>
                                                    <div class="input-field--input-wrapper"><input aria-labelledby="input-field--label" type="text" class="input-field--input" autocomplete="false" tabindex="0" defaultValue={editDomain} onChange={(e) => setEditDomain(e.target.value)} data-gtm-form-interact-field-id="1" /></div>
                                                </div>
                                            </div>
                                        </Form.Group>}

                                    <div className="switch-btn">
                                        <p className='switch-btn-text'>Track New Session</p>
                                        <Switch onChange={handleSwitchChange} checked={checked} uncheckedIcon={false} checkedIcon={false} handleDiameter={20} width={50} height={24} />
                                    </div>
                                    <div hidden={!checked}>
                                        <Form.Control defaultValue={editCampaignName} onChange={(e) => setEditCampaignName(e.target.value)} />
                                    </div>
                                </div>


                                <div className='row  mt-4' style={{ display: "none" }}>
                                    <div className='col-md-12'>
                                        <Accordion1 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>

                                                            <FontAwesomeIcon icon={faQrcode} />
                                                        </div>
                                                        <div className='title-choose'>Choose patterns</div>
                                                    </div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Data Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(0)}><img src='./assets/images/qr-code/1.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(1)}><img src='./assets/images/qr-code/2.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(2)}><img src='./assets/images/qr-code/3.png' alt='text'></img></button>
                                                                        </div>
                                                                        {/* <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(3)}><img src='./assets/images/qr-code/4.png' alt='text'></img></button>
                                                                        </div> */}
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(4)}><img src='./assets/images/qr-code/5.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(5)}><img src='./assets/images/qr-code/6.png' alt='text'></img></button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </div>
                                                        <div className='title-choose'>Choose eyes</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Eyes Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sq')}><img src='./assets/images/eyes/1.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sqd')}><img src='./assets/images/eyes/2.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('dsq')}><img src='./assets/images/eyes/3.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('d')}><img src='./assets/images/eyes/4.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('esq')}><img src='./assets/images/eyes/5.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('ed')}><img src='./assets/images/eyes/6.png' alt='text'></img></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1 >
                                        <Accordion1 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faFileImage} />
                                                        </div>
                                                        <div className='title-choose'>Add Logo</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Add Logo</h4>
                                                            <div className='logo-content'>
                                                                <div className='add-logo'>
                                                                    <div className='image-preview'>
                                                                        {imgdata ?
                                                                            <img src={imgdata} alt='logo'></img> :
                                                                            <div className='loading-screen'>
                                                                                <span className='loader'>No logo</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div className='form-group block-upload'>
                                                                            <div className='upload-logo'>
                                                                                <div className="custom-file">
                                                                                    <input type="file" className="custom-file-input" id="customFile" defaultValue="" onChange={onImageChange} alt='Choose'></input>
                                                                                    <label className="custom-file-label" htmlFor="customFile">Choose</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <button className='btn btn-default btn-remove' onClick={() => setImgdata(null)}>Remove logo</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='media-pattern'>

                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('fb')}><img src='./assets/images/media/1.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tw')}><img src='./assets/images/media/2.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('ig')}><img src='./assets/images/media/3.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wh')}><img src='./assets/images/media/4.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('yt')}><img src='./assets/images/media/5.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('sn')}><img src='./assets/images/media/6.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wg')}><img src='./assets/images/media/7.png' alt='text'></img></div>
                                                                        {/* <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tk')}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`} alt='text'></img></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faPaintbrush} />
                                                        </div>
                                                        <div className='title-choose'>Set Colors</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Set Colors</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='form-group mb-0'>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11" defaultValue="option1" defaultChecked onClick={() => setHidden(true)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio11">Single color</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" onClick={() => setHidden(false)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio2">Color gradient</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Dots Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" className='inp-color-box' value={dotColour} onChange={(e) => onDotsColourChange(e)} />
                                                                        <input type="text" value={dotColour} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 '>
                                                                    <div className='inp-color' hidden={hidden}>
                                                                        <input type="color" className='inp-color-box' onChange={(e) => onDotGradient(e)} value={dotGradient} />
                                                                        <input type="text" value={dotGradient} readOnly />
                                                                    </div>
                                                                </div>

                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Eyes Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={csqColor} className='inp-color-box' onChange={e => onSquareColorChange(e)} />
                                                                        <input type="text" value={csqColor} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" value={cdColor} className='inp-color-box' onChange={e => onCornerDotColorChange(e)} />
                                                                        <input type="text" value={cdColor} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Background Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={bgcolor} className='inp-color-box' onChange={e => onBgColorChange(e)} />
                                                                        <input type="text" value={bgcolor} readOnly />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel5a-content"
                                                id="panel5a-header"
                                            >
                                                <Typography>

                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faBorderAll} />
                                                        </div>

                                                        <div className='title-choose'>Choose frame</div>

                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Choose frame </h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick}><img src='./assets/images/frame/frame1.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick2}><img src='./assets/images/frame/frame2.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick3}><img src='./assets/images/frame/frame3.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick4}><img src='./assets/images/frame/frame4.png' alt='text'></img></button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='col-sm-12'>
                                                                <div className='color-title mt-2'>Frame Text</div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='inp-color'>
                                                                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                    </div>

                                </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <div className='row'>

                                    <div className='col-sm-12'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100 update-url-btn' onClick={() => changeUrl(index)}  > <FontAwesomeIcon icon={faRefresh} /> Update SmartLink</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                        {/* URL edit section */}

                        <Modal
                            show={show2}
                            onHide={() => setShow2(false)}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShow2(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => deleteUrl(index)}>Confirm</Button>
                            </Modal.Footer>
                        </Modal>



                        <Modal dialogClassName="right qr-model-popup" show={show4} onHide={handleClose4}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit QR Code22</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='col-md-12'>
                                    <div className='qr-code-side' ref={downloadRef}>
                                        <div className='qrbox  '>
                                            <div className='canvas-title2 '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div ref={ref}></div>
                                            <div className='canvas-title '>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title3'>
                                                <h2>{text}</h2>
                                            </div>
                                            <div className='canvas-title4'>
                                                <h2>{text}</h2>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='form-group'>
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Edit Destination Url</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editUrl} onChange={(e) => setEditUrl(e.target.value)} />
                                    </div>
                                    <div className='form-group'>
                                        <div className='d-flex flex-wrap'>
                                            <label className='text-bold mr-1'>Edit Short</label>
                                        </div>
                                        <input id="form3Example1c" defaultValue={editDomain} onChange={(e) => setEditDomain(e.target.value)} />
                                    </div>
                                    <div className="switch-btn">
                                        <p className='switch-btn-text'>Is it a new campaign?</p>
                                        <Switch onChange={handleSwitchChange} checked={checked} uncheckedIcon={false} checkedIcon={false} handleDiameter={20} width={50} height={24} />
                                    </div>
                                    <div hidden={!checked}>
                                        <span>Session Name</span>
                                        <Form.Control defaultValue={editCampaignName} onChange={(e) => setEditCampaignName(e.target.value)} />
                                    </div>
                                </div>


                                <div className='row  mt-4'>
                                    <div className='col-md-12'>
                                        <Accordion1 expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>

                                                            <FontAwesomeIcon icon={faQrcode} />
                                                        </div>
                                                        <div className='title-choose'>Choose patterns</div>
                                                    </div></Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Data Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(0)}><img src='./assets/images/qr-code/1.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(1)}><img src='./assets/images/qr-code/2.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(2)}><img src='./assets/images/qr-code/3.png' alt='text'></img></button>
                                                                        </div>
                                                                        {/* <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(3)}><img src='./assets/images/qr-code/4.png' alt='text'></img></button>
                                                                        </div> */}
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(4)}><img src='./assets/images/qr-code/5.png' alt='text'></img></button>
                                                                        </div>
                                                                        <div className="form-check form-check-inline image-radio-btn">

                                                                            <button className='qr-data eyePattern' onClick={() => onDotsChange(5)}><img src='./assets/images/qr-code/6.png' alt='text'></img></button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </div>
                                                        <div className='title-choose'>Choose eyes</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Eyes Patterns</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sq')}><img src='./assets/images/eyes/1.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('sqd')}><img src='./assets/images/eyes/2.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('dsq')}><img src='./assets/images/eyes/3.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('d')}><img src='./assets/images/eyes/4.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('esq')}><img src='./assets/images/eyes/5.png' alt='text'></img></button>
                                                                        <button className='qr-data eyePattern' onClick={() => onEyesChange('ed')}><img src='./assets/images/eyes/6.png' alt='text'></img></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1 >
                                        <Accordion1 expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faFileImage} />
                                                        </div>
                                                        <div className='title-choose'>Add Logo</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Add Logo</h4>
                                                            <div className='logo-content'>
                                                                <div className='add-logo'>
                                                                    <div className='image-preview'>
                                                                        {imgdata ?
                                                                            <img src={imgdata} alt='logo'></img> :
                                                                            <div className='loading-screen'>
                                                                                <span className='loader'>No logo</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div className='form-group block-upload'>
                                                                            <div className='upload-logo'>
                                                                                <div className="custom-file">
                                                                                    <input type="file" className="custom-file-input" id="customFile" defaultValue="" onChange={onImageChange} alt='Choose'></input>
                                                                                    <label className="custom-file-label" htmlFor="customFile">Choose</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <button className='btn btn-default btn-remove' onClick={() => setImgdata(null)}>Remove logo</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='media-pattern'>

                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('fb')}><img src='./assets/images/media/1.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tw')}><img src='./assets/images/media/2.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('ig')}><img src='./assets/images/media/3.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wh')}><img src='./assets/images/media/4.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('yt')}><img src='./assets/images/media/5.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('sn')}><img src='./assets/images/media/6.png' alt='text'></img></div>
                                                                        <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('wg')}><img src='./assets/images/media/7.png' alt='text'></img></div>
                                                                        {/* <div className='qr-data media-icon-qr' onClick={() => onSelectedImageChange('tk')}><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/TikTok_icon.svg`} alt='text'></img></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4a-content"
                                                id="panel4a-header"
                                            >
                                                <Typography>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faPaintbrush} />
                                                        </div>
                                                        <div className='title-choose'>Set Colors</div>
                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Set Colors</h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='form-group mb-0'>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11" defaultValue="option1" defaultChecked onClick={() => setHidden(true)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio11">Single color</label>
                                                                        </div>
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" onClick={() => setHidden(false)}></input>
                                                                            <label className="form-check-label" htmlFor="inlineRadio2">Color gradient</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Dots Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" className='inp-color-box' value={dotColour} onChange={(e) => onDotsColourChange(e)} />
                                                                        <input type="text" value={dotColour} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6 '>
                                                                    <div className='inp-color' hidden={hidden}>
                                                                        <input type="color" className='inp-color-box' onChange={(e) => onDotGradient(e)} value={dotGradient} />
                                                                        <input type="text" value={dotGradient} readOnly />
                                                                    </div>
                                                                </div>

                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Eyes Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={csqColor} className='inp-color-box' onChange={e => onSquareColorChange(e)} />
                                                                        <input type="text" value={csqColor} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color' >
                                                                        <input type="color" value={cdColor} className='inp-color-box' onChange={e => onCornerDotColorChange(e)} />
                                                                        <input type="text" value={cdColor} readOnly />
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    <div className='color-title mt-2'>Background Color</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inp-color'>
                                                                        <input type="color" value={bgcolor} className='inp-color-box' onChange={e => onBgColorChange(e)} />
                                                                        <input type="text" value={bgcolor} readOnly />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                        <Accordion1 expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel5a-content"
                                                id="panel5a-header"
                                            >
                                                <Typography>

                                                    <div className='d-flex align-items-center'>
                                                        <div className='icon'>
                                                            <FontAwesomeIcon icon={faBorderAll} />
                                                        </div>

                                                        <div className='title-choose'>Choose frame</div>

                                                    </div>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <div className='accordion-body'>
                                                        <div className='accordion-warp'>
                                                            <h4 className='accordion-tltle'>Choose frame </h4>
                                                            <div className='row'>
                                                                <div className='col-md-12'>
                                                                    <div className='data-pattern'>

                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick}><img src='./assets/images/frame/frame1.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick2}><img src='./assets/images/frame/frame2.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick3}><img src='./assets/images/frame/frame3.png' alt='text'></img></button>
                                                                        <button className='qr-data media-icon-qr' onClick={onFrameClick4}><img src='./assets/images/frame/frame4.png' alt='text'></img></button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='col-sm-12'>
                                                                <div className='color-title mt-2'>Frame Text</div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='inp-color'>
                                                                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion1>
                                    </div>

                                </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={downloadScreenshot}>Download JPG1</button>
                                        </div>
                                    </div>

                                    <div className='col-sm-6'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={downloadScreenshot_png}>Download PNG1</button>
                                        </div>
                                    </div>

                                    <div className='col-sm-6'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={() => downloadQr(fileid)}>Download SVG1</button>
                                        </div>
                                    </div>




                                    <div className='col-sm-6'>
                                        <div className='download-btn-bar'>
                                            <button className='download-btn w-100' onClick={() => changeUrl(index)}  >Save this QR1</button>
                                        </div>
                                    </div>



                                </div>
                            </Modal.Footer>
                        </Modal>


                        <div className='card'>
                            <div className='card-body'>
                                <div className='qr-list'>
                                    <div className="col-md-12">

                                        <div className="page-hrader">
                                            <div className="page-title url-page-header">
                                                <div className='header-title-dashboard'>
                                                    <h4 className="h2"><FontAwesomeIcon icon={faLink} /> SmartLinks</h4>
                                                </div>
                                                <div className="right-btn-view url-btn-view">
                                                    <Link to="/Qr" >
                                                        <a className="" href="#!">
                                                            <Button variant='primary'><FontAwesomeIcon icon={faQrcode} /> New QR</Button>
                                                        </a>
                                                    </Link>
                                                    <Button style={{ "margin-left": "10px" }} variant='primary' onClick={handleButton} ><FontAwesomeIcon icon={faLink} /> New SmartLink</Button>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="doc_list_step">
                                            <div className="doc_list_step_item">
                                                <h5 className="card-title">List of your Shortened URLs & associated QRcode</h5>
                                            </div>
                                        </div>
                                        <div className="qrlist-container">
                                            <table>
                                                <tbody>
                                                    {list.map((value, index) => (
                                                        <tr key={index} className="smartlink-tr">
                                                            <td className="qr-code-list-view smart-link-view">
                                                                <p className='smart-clik' ><b>{value.clicks}</b> <br />Clicks</p>
                                                                <div className="qr-code-list-view-box">
                                                                    <img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/${value.file}.svg?t=${Date.now()}`} alt="alt" />
                                                                    <div className='qr-code-list-view-box-title'>
                                                                        <h5 className='name'><a target="_blank" href={value.furl} className='smarturl-title'>{value.title}</a></h5>
                                                                        <div className='qr-code-list-view-box-title-btn'>
                                                                            <p className='url' style={{ color: '#26695c' }}>{value.domain}/{value.surl}</p>
                                                                            {/* <h6 className='full-url'>{value.furl}</h6> */}
                                                                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                                                <CopyToClipboard text={`${value.domain}/${value.surl}`}>
                                                                                    <button className="action-item-view copy-btn" style={{ fontSize: '16px' }}><FontAwesomeIcon icon={faCopy} /></button>
                                                                                </CopyToClipboard>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="action-btn smartlink-btn">
                                                                <div className="action-btn-box">

                                                                    {value.type === 'Dynamic' ? <button className="action-item-view" style={{ display: "none" }} onClick={() => handleEditClick(index)}><FontAwesomeIcon icon={faEdit} />Edit</button> : null}

                                                                    <OverlayTrigger placement="top" overlay={renderTooltip_Metrics}>
                                                                        <Link to={`/Listing?url=${value.urlid}`} > <button className="action-item-view"><FontAwesomeIcon icon={faChartLine} /></button></Link>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={renderTooltip_qrcode}>
                                                                        <button className="action-item-view" onClick={() => handleEditClick_qr(index)}><FontAwesomeIcon icon={faQrcode} /></button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={renderTooltip_link}>
                                                                        <button className="action-item-view" onClick={() => handleEditClick_url(index)}><FontAwesomeIcon icon={faLink} /></button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={renderTooltip_transh}>
                                                                        <button className="action-item-view" onClick={() => onclick(index)}><FontAwesomeIcon icon={faTrashCan} /></button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div >
        </>
    )
}

export default Url;
