import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick} from "../page-demo/script";
import Team from "../blocks/team/TeamTwo";
import Helmet from "../component/common/Helmet";
import {  FiChevronUp } from "react-icons/fi";
import BrandOne from "../elements/Brand";
import Header from '../component/header/HeaderFive';
import FooterTwo from "../component/footer/FooterTwo";






const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--34',
        category: '',
        title: 'Short links, big results',
        description: 'Like a Pro',
        sub_description: 'A URL shortener built with powerful tools to help you grow and protect your brand.',
        buttonText: 'Get Started',
        buttonLink: '/signup',
        slider_image: 'bg_image--35',
    },


]

// const ServiceListOne = [
//     {
    
//         icon: <FiNavigation />,
//         title: 'Business Stratagy',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiPhone />,
//         title: 'Website Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiMail />,
//         title: 'Marketing & Reporting',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
// ]

// const starndardService = [
//     {
//         image: '01',
//         title: 'Thinking Development',
//         description: 'I throw myself down among the tall grass by the stream',
//     },
//     {
//         image: '02',
//         title: 'Business Consulting',
//         description: 'I throw myself down among the tall grass by the stream',
//     },
//     {
//         image: '03',
//         title: 'Biseness Development',
//         description: 'I throw myself down among the tall grass by the stream',
//     },


// ]

const SlideList2 = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--69',
        category: 'NatureXR',
        title_Testimonials: 'Derek Alan Rowe',
        Testimonials_description: 'Using the BrandXR Studio we were able to create an AR application within one week, that won a $20,000 pitch competition and now are partnering with BrandXR to produce our beta for pilot.',
        images: '05',
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--69',
        category: 'NatureXR',
        title_Testimonials: 'Snjay Sharma',
        Testimonials_description: 'Using the BrandXR Studio we were able to create an AR application within one week, that won a $20,000 pitch competition and now are partnering with BrandXR to produce our beta for pilot.',
        images: '04',
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--69',
        category: 'NatureXR',
        title_Testimonials: 'Akash Rajoriya',
        Testimonials_description: 'Using the BrandXR Studio we were able to create an AR application within one week, that won a $20,000 pitch competition and now are partnering with BrandXR to produce our beta for pilot.',
        images: '05',
    }
]

class CorporateBusiness extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)

    }
    openModal() {
        this.setState({ isOpen: true })
    }
    
    

    render() {
        

        
        return (
            <Fragment>
                <Helmet  />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.sub_description ? <p className="sub_description">{value.sub_description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="slider_image">
                                                    <img className="w-100" src="/assets/images/bg/bg-image-35.jpg" alt="slider_image" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 about-bg">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-3  order-lg-1">
                                <div className="about-inner inner">
                                    <div className="about-section-title">
                                        <h3 className="title">The link shortener that has your brand’s back</h3>
                                        {/* <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3  order-lg-2">
                                <div className="about-content">
                                    <div className="about-box">
                                        <div className="about-inner">
                                            <div className="icon">
                                                <span><img src="/assets/images/icons/trust 1.png"alt="Sample"></img></span>
                                            </div>
                                            <div className="about-title">
                                                <h2>Inspire trust</h2>
                                            </div>
                                            <div className="about-description">
                                                <p>With more clicks comes increased brand recognition and consumer trust in your</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3  order-lg-3">
                                <div className="about-content">
                                    <div className="about-box">
                                        <div className="about-inner">
                                            <div className="icon">
                                                <span><img src="/assets/images/icons/results 1.png"alt="Sample"></img></span>
                                            </div>
                                            <div className="about-title">
                                                <h2>Boost results</h2>
                                            </div>
                                            <div className="about-description">
                                                <p>With more clicks comes increased brand recognition and consumer trust in your</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3  order-lg-4">
                                <div className="about-content">
                                    <div className="about-box">
                                        <div className="about-inner">
                                            <div className="icon">
                                                <span><img src="/assets/images/icons/sound-control 1.png"alt="Sample"></img></span>
                                            </div>
                                            <div className="about-title">
                                                <h2>Gain control</h2>
                                            </div>
                                            <div className="about-description">
                                                <p>With more clicks comes increased brand recognition and consumer trust in your</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Team Area  */}
  

                {/* End Team Area  */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <FooterTwo />
            </Fragment>
        )
    }
}
export default CorporateBusiness;
