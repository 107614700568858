import React from 'react'
import {  FiPhone, FiMail, FiNavigation } from "react-icons/fi";

const TeamTwo = (props) => {

    const ServiceListOne = [
        {
            icon: <FiNavigation />,
            title: '403 Street, Avenue,NewYork USA.',

        },
        {
            icon: <FiPhone />,
            title: '012134567879',

        },
        {
            icon: <FiMail />,
            title: 'hello@bxr.com',

        },
    ]
    return (
        // <div className="row">
        //     {itemSlice.map((value , i ) => (
        //         <div className={`${props.column}`} key={i}>
        //             <div className={`team-static ${props.teamStyle}`}>
        //                 <div className="thumbnail">
        //                     <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images"/>
        //                 </div>
        //                 <div className="inner">
        //                     <div className="content">
        //                         <h4 className="title">{value.title}</h4>
        //                         <p className="designation">{value.designation}</p>
        //                     </div>
        //                     <ul className="social-transparent liststyle d-flex" >
        //                         {value.socialNetwork.map((social, index) =>
        //                             <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
        //                         )}
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     ))}
        // </div>
        <form>
            <div className="service-area ptb--30 ">
                <div className="">
                    <div className="row service-one-wrapper justify-content-center">
                        {ServiceListOne.map((val, i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-3" key={i}>
                                <a className="text-center" href="/service-details">
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <section className='form'>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Name</label>
                        <input type="text" className="form-control" id="inputAddress" placeholder="Name" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">Email</label>
                        <input type="email" className="form-control" id="inputEmail4" placeholder="Email" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Address</label>
                        <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputState">Country</label>
                        <select id="inputState" className="form-control">
                            <option selected>Choose...</option>
                            <option>...</option>
                        </select>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-group col-md-12'>
                        <label htmlFor="w3review">Review of W3Schools:</label>

                        <textarea id="w3review" name="text" cols="200" className="form-control" height="200px">
                            
                        </textarea>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </section>
        </form>
    )
}
export default TeamTwo
