import React from 'react'
import './Dashboard.css'
import Nav from '../component/inner_pages/Nav'
import Sidebar from '../component/inner_pages/Sidebar'
import Cards from '../component/inner_pages/Cards'
import Graph from '../component/inner_pages/Graph'
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
function Dashboard() {
    const history = useHistory()
    const [newurl, setNewurl] = useState('')
    const [show, setShow] = useState(false)
    const [show3, setShow3] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [domains, setDomains] = useState([])
    const [type, setType] = useState('Static')
    const [custom, setCustom] = useState(-1)
    const [visitors, setVisitors] = useState([])

    const renderTooltip = props => (
        <Tooltip {...props}>Copy</Tooltip>
    );


    async function create(e) {
        setLoading(true)
        setShow(false)
        e.preventDefault()
        var dom
        if (custom !== -1) {
            dom = domains[custom].domain
        }
        else if (custom === -1) {
            dom = `${process.env.REACT_APP_PUBLIC_URL}:5000`
        }

        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/short`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                newurl,
                dom,
                type
            }),
        })
        const data = await req.json()

        if (data.status === 'created successfully') {
            setNewurl('')
            toast('Created Successfully')
        }
        else if (data === 'already exists') {
            toast('You have already created a short url for this.')
        }
        else if (data === 'token expired') {
            localStorage.clear()
            history.push('/login')
        }
        else {
            toast('Some error has occurred')
        }
        setLoading(false)

    }
    const handleClick = () => {
        if (newurl === '') {
            toast.error('Please enter a valid url')
        }
        else {
            setShow(true)
        }
    }
    useEffect(() => {
        setLoading(true)
        const getDomains = async () => {

            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domains`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })
            const data = await req.json()

            if (data.status === 'ok') {
                if (data.list)
                    setDomains(data.list)

            }

        }
        const getLastUrl = async () => {
            setLoading(true)
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/lastestUrl`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token')

                }
            })
            const data = await req.json()
            if (data === 'token expired') {
                localStorage.clear()
                history.push('/login')
            }
            else if (data !== 'no data') {
                setData(data)
                console.log(data)

            }
            setLoading(false)


        }
        //get latest visitors
        const getVisitors = async () => {
            setLoading(true)
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_VISITOR_URL}/recent/latestVisitors`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token')

                },
            })
            const data = await req.json()
            setVisitors(data)
            //setLoading(false)
            console.log('name')
            console.log(Object.keys(visitors).length )

        }

        setLoading(false)
        getDomains()
        getLastUrl()
        getVisitors()



    }, [])

    const handleRadio = (props) => {
        setType(props)
    }

    return (
        <>
            <ToastContainer />
            <Loading loading={loading} loaderColor="#f16022" />;
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create SmartLink</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Enter Destination Url</Form.Label>
                            <Form.Control as="textarea" rows={5} defaultValue={newurl} required onChange={(e) => setNewurl(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio3" value="option3" defaultChecked onClick={() => handleRadio('Static')} />
                            <label className="form-check-label" htmlFor="inlineRadio3" onClick={() => handleRadio('Static')} > Static</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input block-add" type="radio" name="inlineRadioOptions1" id="inlineRadio4" value="option4" onClick={() => handleRadio('Dynamic')} />
                            <label className="form-check-label" htmlFor="inlineRadio4" onClick={() => handleRadio('Dynamic')}> Dynamic</label>
                        </div>
                    </div>


                    <div className='footer-modal'>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" defaultChecked onClick={() => setShow3(false)} />
                                <label className="form-check-label" htmlFor="inlineRadio1" onClick={() => setShow3(false)} >Simple</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input block-add" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={() => setShow3(true)} />
                                <label className="form-check-label" htmlFor="inlineRadio2" onClick={() => setShow3(true)} >Custom</label>
                            </div>
                        </div>
                    </div>
                    {show3 ?
                        <select aria-label="Default select example" onChange={(e) => setCustom(e.target.value)} >
                            <option value="">Select the custom domain</option>
                            {domains.map((value, index) => (
                                value.status === 'verified'
                                    ? (<option key={index} value={index} >{value.domain}</option>)
                                    : null

                            ))}


                        </select>
                        : null}
                </Modal.Body>
                <Modal.Footer>



                    <div>
                        <Button variant="secondary footer-model-btn mr-3" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={create}>
                            Create
                        </Button>
                    </div>






                </Modal.Footer>
            </Modal>

            <div >
                <Nav />
                <div className="container-fluid">
                    <div className="row">

                        <Sidebar />
                        <main role="main" className="col-md-12 col-sm-12 ml-sm-auto col-lg-10 main-warp pt--100 page-body">
                            <div className='header-title-dashboard'>
                                <h4 className="h2"> <FontAwesomeIcon icon={faHome} />Dashboard</h4>
                            </div>
                            <div className="row">
                                <div className="box-col-12 des-xl-100 col-xl-2">
                                    <Cards />
                                </div>
                                <div className="box-col-12 des-xl-100 col-xl-10">
                                    <div className="card">
                                        <div className="card-body">
                                            <Graph />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="card-title">
                                                <h5 className="fontWeight-bolder">Create SmartLink</h5>
                                            </div>
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">

                                                        <input type="text" className="form-control" id="inputtext" required placeholder="paste a long link" onChange={(e) => setNewurl(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-btn-view ms-auto"><button type="button" className="btn btn-primary" onClick={handleClick}>Create SmartLink</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6>Recent Smartlinks</h6>
                                            <div className="table-responsive">

                                                {/* {loading ?  */}
                                                {Object.keys(data).length === 0 ?
                                                    <p className='smartlink-text'>Here you’ll see a list of your most recently created SmartLinks</p> :
                                                    <table className="table smart-link-deta">
                                                        <tbody>

                                                            {data.map((value, index) => (
                                                                <tr>
                                                                    <td>

                                                                        <div className="link-title">
                                                                            <div className="images-sec">
                                                                                {
                                                                                    value.icon ?
                                                                                        <img className="icon" src={value.icon} alt="alt" />
                                                                                        : <img className="icon" src="../../public/favicon.ico" alt="alt" />}
                                                                                <a className="link" href={value.url} >
                                                                                    {value.title}
                                                                                </a>
                                                                            </div>
                                                                            <div className="short-link-line">
                                                                                <p className="short-link">{value.domain + "/" + value.code}</p>
                                                                                <div className="copy=btn">
                                                                                    <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                                                        <CopyToClipboard text={`${value.domain}/${value.code}`}>
                                                                                            <button className="action-btn-item-view" >Copy</button>
                                                                                        </CopyToClipboard>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </div>
                                                                            <div className="data-show-line">
                                                                                {/* <div className="link-time">
                                                                            <span>12:20 pm</span>
                                                                        </div> */}
                                                                                <div className="Clicks">
                                                                                    <span>{value.total} Total Clicks</span>
                                                                                </div>
                                                                                <div className="Unique Clicks">
                                                                                    <span>{value.unique} Unique Clicks</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='resent-view'>
                                                <h6>Recent Visits</h6>
                                                <div className="table-responsive">
                                                    {Object.keys(visitors).length === 0 ?
                                                        <p className='smartlink-text'>Here you’ll see a listing of recent visitors to your SmartLinks</p> :
                                                        <table className="table">
                                                            <tbody>
                                                                {visitors.map((value, index) => (
                                                                <>
                                                                        {Object.keys(value.urldata).length === 0 ?
                                                                            <></> :
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div class="link-title">
                                                                                        <div class="images-sec"><img class="icon" src={value.urldata[0].icon} alt="alt" /><a class="link" href='#!'>{value.urldata[0].title}</a></div>
                                                                                        <div class="short-link-line">
                                                                                            <p class="short-link">{value.urldata[0].domain + "/" + value.urldata[0].code}</p>
                                                                                            <OverlayTrigger placement="top" overlay={renderTooltip}>
                                                                                                <CopyToClipboard text={value.urldata[0].domain + "/" + value.urldata[0].code}>
                                                                                                    <div class="copy=btn"><button class="action-btn-item-view">Copy</button></div>
                                                                                                </CopyToClipboard>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                        <div class="data-show-line">
                                                                                            <div class="Clicks"><span>Country: {value.country},</span></div>
                                                                                            <div class="Unique Clicks"><span>City: {value.city}</span></div>
                                                                                            <div class="Unique Clicks"><span>Os: {value.os}</span></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                       }
                                                                        </>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

        </>
    )

}


export default Dashboard;
