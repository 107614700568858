import React from 'react'
import './Qrdoc.css';
import Nav from '../component/inner_pages/Nav'
import Sidebar from '../component/inner_pages/Sidebar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
function Qrdoc() {


    return (
        <>
            <ToastContainer />
            <div>
                <Nav />
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 main-warp pt--100 page-body">
                            <div >
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className="card-title" style={{ fontSize: '26PX' }}> <FontAwesomeIcon icon={faFile} /> BXR APIs Docs</h5>
                                                        <p className="card-text doc_text">Our custom qr code API offers a professional solution for brands that need to have custom qr code templates with data tracking system, dynamic qr codes or qr codes in bulk and have qr codes integrated in their CRM.</p>
                                                        <p className="card-text doc_text">Our API and is available on 'Mashape' for any other questions you can always 'contact us' our API can be used world wide, if you need your QR codes available in China, please contact us. We can also do customized QR code for mobile payments.</p>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="qr_overview">
                                                            <h5 className="card-title">qrId:</h5>
                                                            <div className="qr_list">
                                                                <div className="row">
                                                                    <div className="col-lg-2 col-md-4 col-6">
                                                                        <div className="qr_list_item">
                                                                            <img src='./assets/images/qr_example/qr_example_1.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2 col-md-4 col-6">
                                                                        <div className="qr_list_item">
                                                                            <img src='./assets/images/qr_example/qr_example_1.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2 col-md-4 col-6">
                                                                        <div className="qr_list_item">
                                                                            <img src='./assets/images/qr_example/qr_example_1.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2 col-md-4 col-6">
                                                                        <div className="qr_list_item">
                                                                            <img src='./assets/images/qr_example/qr_example_1.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2 col-md-4 col-6">
                                                                        <div className="qr_list_item">
                                                                            <img src='./assets/images/qr_example/qr_example_1.png' alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h2 className="Card-tltle" style={{ fontSize: '36PX' }}>Documentation</h2>
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">Create QR Code</h5>
                                                                <p className="card-text"><b>1. </b>First login and get the <b><em>API key</em></b> to authorise in <b>Track Data  Account setting  API key</b></p>
                                                                <p className="card-text"><b>2. </b>Add this API key in every req (GET/POST) as <b>Authorization: Bearer API KEY</b></p>
                                                                <p className="card-text"><b>3. </b>Generate Static custom QR code</p>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table className='responsive'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">URL</td>
                                                                        <td className="description">/api/qr</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Methods</td>
                                                                        <td className="description">POST</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Authorization</td>
                                                                        <td className="description">Bearer API KEY</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Response</td>
                                                                        <td className="description">Binary Image File (PNG, SVG)</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">Request parameter</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-parameters">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">qrCategory</td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">url</td>
                                                                        <td className="description">qrCategory define different type of qr like url, Vcard, facebook, etc. Check below qrCategory table.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrCategory</td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">url</td>
                                                                        <td className="description">qrCategory define different type of qr like url, Vcard, facebook, etc. Check below qrCategory table.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrCategory</td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">url</td>
                                                                        <td className="description">qrCategory define different type of qr like url, Vcard, facebook, etc. Check below qrCategory table.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrCategory</td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">url</td>
                                                                        <td className="description">qrCategory define different type of qr like url, Vcard, facebook, etc. Check below qrCategory table.</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">Example</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="name">URL</td>
                                                                            <td className="description">/api/qr/static</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Methods</td>
                                                                            <td className="description">POST</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Parameter</td>
                                                                            <td className="description">
                                                                                <pre>
                                                                                    "size": 500,<br />
                                                                                    "colorDark": "rgb(5,64,128)",<br />
                                                                                    "logo": "scan_me.png",<br />
                                                                                    "eye_outer": "eyeOuter2",<br />
                                                                                    "eye_inner": "eyeInner1",<br />
                                                                                    "qrData": "pattern0",<br />
                                                                                    "backgroundColor": "rgb(255,255,255)",<br />
                                                                                    "transparentBkg": false,<br />
                                                                                    "qrCategory": "url",<br />
                                                                                    "text": "https://qrcode-tiger.com"<br />
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Response</td>
                                                                            <td className="description"><img alt="" />
                                                                                <pre>
                                                                                    "size": 500,<br />
                                                                                    "colorDark": "rgb(5,64,128)",<br />
                                                                                    "logo": "scan_me.png",<br />
                                                                                    "eye_outer": "eyeOuter2",<br />
                                                                                    "eye_inner": "eyeInner1",<br />
                                                                                    "qrData": "pattern0",<br />
                                                                                    "backgroundColor": "rgb(255,255,255)",<br />
                                                                                    "transparentBkg": false,<br />
                                                                                    "qrCategory": "url",<br />
                                                                                    "text": "https://qrcode-tiger.com"<br />
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">4. Get a list of all QR campaigns of different QR categories</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">URL</td>
                                                                        <td className="name">/api/qr/static</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Methods</td>
                                                                        <td className="description">POST</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Authorization</td>
                                                                        <td className="description">Bearer API KEY</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Response</td>
                                                                        <td className="description">Binary Image File (PNG, SVG)</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h4 className="card-title">GET /campaign/?page=1&limit=5&sort=1</h4>
                                                                <h6 className="card-title">Query parameters</h6>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="name">Default</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">page</td>
                                                                        <td className="description">1</td>
                                                                        <td className="description">no. of page</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">limit</td>
                                                                        <td className="description">5</td>
                                                                        <td className="description">no. of campaign per page</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Sort</td>
                                                                        <td className="description">1</td>
                                                                        <td className="description">Sort by Date(1) or Name(2)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">category</td>
                                                                        <td className="description">Url</td>
                                                                        <td className="description">category define different type of qr like url, Vcard, facebook, etc. Check below qrCategory table.</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">Example</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="name">URL</td>
                                                                            <td className="description">/api/qr/static</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Methods</td>
                                                                            <td className="description">POST</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Parameter</td>
                                                                            <td className="description">
                                                                                <pre>
                                                                                    "size": 500,<br />
                                                                                    "colorDark": "rgb(5,64,128)",<br />
                                                                                    "logo": "scan_me.png",<br />
                                                                                    "eye_outer": "eyeOuter2",<br />
                                                                                    "eye_inner": "eyeInner1",<br />
                                                                                    "qrData": "pattern0",<br />
                                                                                    "backgroundColor": "rgb(255,255,255)",<br />
                                                                                    "transparentBkg": false,<br />
                                                                                    "qrCategory": "url",<br />
                                                                                    "text": "https://qrcode-tiger.com"<br />
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Response</td>
                                                                            <td className="description">
                                                                                <pre>
                                                                                    "size": 500,<br />
                                                                                    "colorDark": "rgb(5,64,128)",<br />
                                                                                    "logo": "scan_me.png",<br />
                                                                                    "eye_outer": "eyeOuter2",<br />
                                                                                    "eye_inner": "eyeInner1",<br />
                                                                                    "qrData": "pattern0",<br />
                                                                                    "backgroundColor": "rgb(255,255,255)",<br />
                                                                                    "transparentBkg": false,<br />
                                                                                    "qrCategory": "url",<br />
                                                                                    "text": "https://qrcode-tiger.com"<br />
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">5. Generate a Dynamic custom Visual QR code</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">URL</td>
                                                                        <td className="name">/api/qr/static</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Methods</td>
                                                                        <td className="description">POST</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Authorization</td>
                                                                        <td className="description">Bearer API KEY</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Response</td>
                                                                        <td className="description">Binary Image File (PNG, SVG)</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h6 className="card-title">Query parameters</h6>
                                                            </div>
                                                        </div>
                                                        <div className="api-parameters">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="description"></td>
                                                                        <td className="name">Type</td>
                                                                        <td className="name">Default</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrType</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">qr2</td>
                                                                        <td className="description">qrType define the behaviour of qr like single redirect url or multi redirect url (“qr2” or “murl”)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrUrl</td>
                                                                        <td className="description"></td>
                                                                        <td className="description">string</td>
                                                                        <td className="description">https://qrcode-tiger.com</td>
                                                                        <td className="description">Your desired redirect URL will be embedded in QR</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">Example</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="name">URL</td>
                                                                            <td className="description">/api/qr/static</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Methods</td>
                                                                            <td className="description">POST</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Parameter</td>
                                                                            <td className="description">
                                                                                <pre>
                                                                                    "size": 500,<br />
                                                                                    "colorDark": "rgb(5,64,128)",<br />
                                                                                    "logo": "scan_me.png",<br />
                                                                                    "eye_outer": "eyeOuter2",<br />
                                                                                    "eye_inner": "eyeInner1",<br />
                                                                                    "qrData": "pattern0",<br />
                                                                                    "backgroundColor": "rgb(255,255,255)",<br />
                                                                                    "transparentBkg": false,<br />
                                                                                    "qrCategory": "url",<br />
                                                                                    "text": "https://qrcode-tiger.com"<br />
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="name">Response</td>
                                                                            <td className="description"><img src='' alt="" />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12">
                                                        <h5 className="Card-tltle">Dot Patterns</h5>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="name">Type</td>
                                                                        <td className="name">Value</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Eyes Pattern</td>
                                                                        <td className="description">String</td>
                                                                        <td className="description">rounded</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">dots</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/2.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">classy</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/3.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">classy-rounded</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/4.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">square</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/5.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">extra-rounded</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/qr-code/6.png'></img></button>
                                                                        </td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h5 className="Card-tltle">Qr Data</h5>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="name">Type</td>
                                                                        <td className="name">Value</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrData</td>
                                                                        <td className="description">String</td>
                                                                        <td className="description">square</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/New folder/eyeOuter0.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">dot</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/New folder/eyeOuter1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">extra-rounded</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/New folder/eyeOuter2.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                    

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h5 className="Card-tltle">Qr Data</h5>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="name">Type</td>
                                                                        <td className="name">Value</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">qrData</td>
                                                                        <td className="description">String</td>
                                                                        <td className="description">square</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/New folder/eyeInner0.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">dot</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/New folder/eyeInner1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12">
                                                        <h5 className="Card-tltle">Frame (Default : null)</h5>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">Parameter</td>
                                                                        <td className="name">Type</td>
                                                                        <td className="name">Value</td>
                                                                        <td className="name">Description</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">frame</td>
                                                                        <td className="description">Number</td>
                                                                        <td className="description">1</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/eyes/1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">2</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/eyes/1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">3</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/eyes/1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name"></td>
                                                                        <td className="description"></td>
                                                                        <td className="description">4</td>
                                                                        <td>
                                                                            <button className="eyePattern smallEye"><img role="presentation" src='./assets/images/eyes/1.png'></img></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-12">
                                                        <div className="doc_list_step">
                                                            <div className="doc_list_step_item">
                                                                <h5 className="card-title">6. Upload your logo</h5>
                                                            </div>
                                                        </div>
                                                        <div className="api-function">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="name">URL</td>
                                                                        <td className="name">/accounts/uploads/</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Methods</td>
                                                                        <td className="description">POST</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Content-Type</td>
                                                                        <td className="description">multipart/form-data</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Form-Field</td>
                                                                        <td className="description">File</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="name">Response</td>
                                                                        <td className="description">Image file url “qrUrl”: 1526460566643.png</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </main>
                    </div>
                </div>
            </div>

        </>
    )

}


export default Qrdoc;
