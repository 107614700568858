import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import { useHistory } from 'react-router-dom'
import { AiFillFile, AiFillSetting } from "react-icons/ai";
import { faLink, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
function Sidebar() {
    const history = useHistory()
    const [uname, setUname] = useState('')


    useEffect(() => {
        const checktoken = async () => {
            const token = localStorage.getItem('token')
            if (token) {
                const user = jwt.decode(token)
                if (!user) {
                    localStorage.clear()
                    alert('Please login again')
                    history.push('/login')
                }
                if (Date.now() >= JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000) {
                    alert('You are logged out')
                    localStorage.clear()
                    history.push('/login')
                }
                if (user) {
                    setUname(user.name)
                }
            }
            else {
                history.push('/login')
            }
        }
        checktoken()
        const t = setInterval(checktoken, 60000)
        return () => clearInterval(t);

    })


    return (
        <>


            <nav id="sidebarMenu sidecoll" className="col-lg-2 col-md-6 col-sm-12 d-md-block bg-light sidebar collapse sidecoll">

                <div className="sidebar-sticky sidecool ">
                    
                    <div className="view-profile justify-content-center">
                        <div className="image">
                            <img src='./assets/images/1.png' alt="bxr"></img>
                        </div>
                        <div className="user-name">
                            <h5>{uname}</h5>
                        </div>
                    </div>
                    <ul className="nav flex-column mainside">
                        <li className="nav-item">
                            <Link to="/dashboard" >
                                <a className="nav-link sidebar-nav-item dashboard" href="#!">
                                    <FontAwesomeIcon icon={faHome} />
                                    Dashboard..
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Url" >
                                <a className="nav-link sidebar-nav-item Url statistics List" href="#!">
                                    <FontAwesomeIcon icon={faLink} />
                                    URL
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/domains" >
                                <a className="nav-link sidebar-nav-item domains" href="#!">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                        className="bi bi-list" viewBox="0 0 16 16">
                                        <path fillRule="evenodd"
                                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                    Custom Domains
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Qr" >
                                <a className="nav-link sidebar-nav-item Qr" href="#!">
                                    <FontAwesomeIcon icon={faQrcode} />
                                    QR Code
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Qr-List" >
                                <a className="nav-link sidebar-nav-item Qr-List" href="#!">
                                    <FontAwesomeIcon icon={faQrcode} />
                                    QR List
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/settings" >
                                <a className="nav-link sidebar-nav-item settings" href="#!">
                                    <AiFillSetting />
                                    Settings
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Qrdoc" >
                                <a className="nav-link sidebar-nav-item Qrdoc" href="#!">
                                    <AiFillFile />
                                    Qrdoc
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>

        </>
    )
}

export default Sidebar;