import React, { useEffect, useState, useRef } from 'react'
import './Qrdoc.css';
import Nav from '../component/inner_pages/Nav'
import Sidebar from '../component/inner_pages/Sidebar'
import 'react-toastify/dist/ReactToastify.css';
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from 'react-toastify';
import { confirm } from "react-confirm-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
function Templates() {

  const renderTooltip_transh = props => (
    <Tooltip {...props}>Delete</Tooltip>
  );

  const renderTooltip_edit = props => (
    <Tooltip {...props}>Edit</Tooltip>
  );

  const [templateList, setTemplateList] = useState([])
  const [loading, setLoading] = useState(false)


  //get request to get all templates from server
  const getTemplates = async () => {
    const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/gettemplates`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      },
    })
    const data = await res.json()
    setTemplateList(data.data)
    console.log(data)
  }

  //post request to dwelete template
  const deleteTemplate = async (id) => {

    const result = await confirm("Are you sure you want to delete this templet?");
    if (result) {
      setLoading(true)
      const code = templateList[id].code
      var fd = new FormData();
      fd.append('code', code)
      const res = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/qr/deleteTemplate`, {
        method: 'POST',
        headers: {
          'x-access-token': localStorage.getItem('token')
        },
        body:
          fd
      })
      const data = await res.json()
      if (data === 'success') {
        setLoading(false)
        toast.success('Template Deleted Successfully')
      }
      else {
        setLoading(false)
        toast.error('Something went wrong')
      }
    }





  }


  useEffect(() => {
    getTemplates()
  }, [])

  return (
    <>
      <ToastContainer />
      <div>
        <Nav />
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 page-body">
              <div class="card">
                <div class="card-body">
                  <div class="qr-list">
                    <div class="col-md-12">
                      <div className="page-hrader">
                        <div className="page-title url-page-header">
                          <div className='header-title-dashboard'>
                            <h4 className="h2"><FontAwesomeIcon icon={faQrcode} /> QR Code Styles</h4>
                          </div>
                          <div className="right-btn-view url-btn-view">
                            <Link to="/Qr" >
                              <a className="" href="#!">
                                <Button variant='primary'><FontAwesomeIcon icon={faQrcode} /> New QR Template</Button>
                              </a>
                            </Link>
                          </div>

                        </div>
                      </div>
                      <div class="doc_list_step">
                        <div class="doc_list_step_item">
                          <h5 class="card-title">You can create custom qr styles to apply to your future SmartLinks.
                          </h5>
                        </div>
                      </div>
                      <div class="qrlist-container">
                        <table>
                          <tbody>



                            {templateList.map((template, index) => (
                              <tr>
                                <td class="qr-code-list-view smart-link-view">
                                  <div class="qr-code-list-view-box smart-img-view"><img src={`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/Temp_${template.code}.svg`} alt="alt" /></div>
                                </td>
                                <td class="action-btn">
                                  <div class="action-btn-box">
                                    <OverlayTrigger placement="top" overlay={renderTooltip_edit}>
                                      <button className="action-item-view" ><FontAwesomeIcon icon={faEdit} /></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={renderTooltip_transh}>
                                      <button className="action-item-view" onClick={() => onclick(index)}><FontAwesomeIcon icon={faTrashCan} /></button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            ))}



                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

    </>
  )

}


export default Templates;
