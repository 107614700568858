import React, { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import { AiFillFile, AiFillSetting } from "react-icons/ai";
import { faLink, faQrcode, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
function Sidebar() {
    const history = useHistory()
    const [uname, setUname] = useState('')
    const [loading, setLoading] = useState(false)
    const active = window.location.pathname.slice(1)
    useEffect(() => {
        const checktoken = async () => {
            setLoading(true)
            const token = localStorage.getItem('token')
            if (token) {
                const user = jwt.decode(token)
                if (!user) {
                    localStorage.clear()
                    alert('Please login again')
                    history.push('/login')
                }
                if (Date.now() >= JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000) {
                    alert('You are logged out')
                    localStorage.clear()
                    history.push('/login')
                }
                if (user) {
                    setUname(user.name)
                }
            }
            else {
                history.push('/login')
            }
            setLoading(false)
        }
        console.log(active)
        document.querySelector(`.${active}`).classList.toggle('active')
        checktoken()
        const t = setInterval(checktoken, 60000)
        return () => clearInterval(t);


    }, [active])





    return (
        <>
            <Loading loading={loading} loaderColor="#f16022" />
            <nav id="sidebarMenu sidecoll" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">

                <div className="sidebar-sticky sidecool ">
                    <div className="view-profile justify-content-center">
                        <div className="image">
                            <img src='./assets/images/1.png' alt="bxr"></img>
                        </div>
                        <div className="user-name">
                            <h5>{uname}</h5>
                        </div>
                    </div>
                    <ul className="nav flex-column mainside">
                        <li className="nav-iten">
                            <Link to="/dashboard" >
                                <a className="nav-link sidebar-nav-item dashboard" href="#!">
                                    <FontAwesomeIcon icon={faHome} />
                                    Dashboard..
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Url" >
                                <a className="nav-link sidebar-nav-item Url statistics Listing" href="#!">
                                    <FontAwesomeIcon icon={faLink} />
                                    SmartLinks
                                </a>
                            </Link>
                        </li>


                        <li className="nav-item">
                            <Link to="/templates" >
                                <a className="nav-link sidebar-nav-item Qr templates" href="#!">
                                    <FontAwesomeIcon icon={faQrcode} />
                                    QR Templates  
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/domains" >
                                <a className="nav-link sidebar-nav-item domains" href="#!">
                                    <FontAwesomeIcon icon={faServer} />
                                    My Domains
                                </a>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/VanitySmartlinks" >
                                <a className="nav-link sidebar-nav-item VanitySmartlinks" href="#!">
                                    <AiFillFile />
                                    Vanity Tags
                                </a>
                            </Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link to="/settings" >
                                <a className="nav-link sidebar-nav-item settings" href="#!">
                                    <AiFillSetting />
                                    Settings
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/Qrdoc" >
                                <a className="nav-link sidebar-nav-item Qrdoc" href="#!">
                                    <AiFillFile />
                                    API Docs
                                </a>
                            </Link>
                        </li>

                       
                    </ul>
                </div>
            </nav>

        </>
    )
}

export default Sidebar;