import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";


const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
]

const FooterTwo = () => {
    return (
        <>

            <div className="footer-style-2 ptb--30">
                <div className='container'>
                    <div className="wrapper plr--50 plr_sm--20">

                        <div className="row justify-content-between">

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="inner">
                                    <div className="logo text-sm-left mb_sm--20">
                                        <a href="/home-one">
                                            <img src="/assets/images/logo/BrandXR_footer.png" alt="Logo images" style={{ width: '80%' }} />
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="inner text-center">
                                    <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                        Copyright © BrandXR
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FooterTwo;