// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';


import CorporateBusiness from './home/CorporateBusiness';
import error404 from "./elements/error404";
import Login from './home/Login';
import Signup from './home/Signup';
import Forgot from './home/Forgot';
import Url from './home/Url';
import Statistics from './home/Statistics';


import Reset from './home/Reset'



import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Dashboard from './home/Dashboard';
import Manage from './home/Manage';
import Qr from './home/Qr';
import List from './home/Qr-List';
import Settings from './home/Settings';
import Qrdoc from './home/Qrdoc';
import Listing from './home/Listing';
import VanitySmartlinks from './home/VanitySmartlinks';
import Templets from './home/Templets';



class Root extends Component {



    render() {
        

        return (
            <BrowserRouter basename={'/'}>

                {/* <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/> */}
                <PageScrollTop>
                    <Switch>
                    {/* <Loading loading={loading}  loaderColor="#f16022" />; */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness} />
                        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/signup`} component={Signup} />
                        <Route exact path={`${process.env.PUBLIC_URL}/forgot`} component={Forgot} />

                        <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
                        <Route exact path={`${process.env.PUBLIC_URL}/reset`} component={Reset} />
                        <Route exact path={`${process.env.PUBLIC_URL}/Url`} component={Url} />
                        <Route exact path={`${process.env.PUBLIC_URL}/statistics`} component={Statistics} />
                        <Route exact path={`${process.env.PUBLIC_URL}/domains`} component={Manage} />
                        <Route exact path={`${process.env.PUBLIC_URL}/Qr`} component={Qr} />
                        <Route exact path={`${process.env.PUBLIC_URL}/Qr-List`} component={List} />
                        <Route exact path={`${process.env.PUBLIC_URL}/settings`} component={Settings} />
                        <Route exact path={`${process.env.PUBLIC_URL}/Qrdoc`} component={Qrdoc} />
                        <Route exact path={`${process.env.PUBLIC_URL}/Listing`} component={Listing} />
                        <Route exact path={`${process.env.PUBLIC_URL}/VanitySmartlinks`} component={VanitySmartlinks} />
                        <Route exact path={`${process.env.PUBLIC_URL}/templates`} component={Templets} />

                        
                        


 


                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} /> 

                    </Switch>
                </PageScrollTop>
                {/* <FooterTwo /> */}
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
