import React, { useEffect, useState } from 'react'
import Nav from '../component/inner_pages/Nav';
import Sidebar from '../component/inner_pages/Sidebar';
import { Button, Modal, Form } from 'react-bootstrap';
import './Url.css'
import Loading from "react-fullscreen-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faIdBadge, faServer, faAdd, faChalkboardUser } from '@fortawesome/free-solid-svg-icons'
function Manage() {

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false)
    const [list, setList] = useState([])
    const [domain, setDomain] = useState('')
    const [index, setIndex] = useState(-1)
    const [checking, setChecking] = useState(false)
    const [loading, setLoading] = useState(false)
    function onclick(index) {
        setShow2(true)
        setIndex(index)
    }
    async function addDomain(e) {
        setLoading(true)
        setShow(false)
        e.preventDefault()
        const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domain/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                domain
            }),
        })
        const data = await response.json()
        if (data.status === 'Added Domain') {
            
            toast('Successfully added domain')
            window.location.reload();
        }

        setLoading(false)
    }
    async function deleteDomain(index) {
        setShow2(false)
        setLoading(true)
        const domain = list[index].domain
        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domain/delete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')

            },
            body: JSON.stringify({
                domain,
            })
        })

        const data = await req.json()
        if (data.status === 'ok') {
            toast('deleted successfully')
        }

        setLoading(false)

    }

    useEffect(() => {
        const getDomains = async () => {
            setLoading(true)
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domains`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })
            const data = await req.json()
            if (data.status === 'ok') {
                setList(data.list)
            }
            setLoading(false)
        }
        getDomains()
    }, [])
 

    async function check(index) {
        setLoading(true)
        setChecking(true)
        const domain = list[index].domain
        console.log(domain)
        const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/domain/verify/${domain}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token')
            },
        })
        const data = await req.json()
        console.log(data)
        if (data.status === 'verified') {
            let newList = [...list]
            newList[index].status = 'verified'
            setList(newList)

        }


        setChecking(false)
        setLoading(false)

    }

    return (
        <>

            <Loading loading={loading} loaderColor="#f16022" />;

            <ToastContainer />
            <Nav />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt--100 ">

                        <div className="page-hrader">
                            <div className="page-title domain-page-header">
                                <div className='header-title-dashboard'>
                                    <h4 className="h2"> <FontAwesomeIcon icon={faServer} />Custom Branded Domains</h4>
                                </div>
                                <div className="right-btn-view domain-btn-view">
                                    <Button variant='primary' className='mr-1' onClick={() => setShow3(true)} > <FontAwesomeIcon icon={faChalkboardUser} /> Instructions</Button>
                                    <Button variant='primary' onClick={() => setShow(true)} > <FontAwesomeIcon icon={faAdd} /> Add a new domain</Button>
                                </div>
                            </div>

                            <Modal show={show} onHide={() => setShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <FontAwesomeIcon icon={faAdd} />Add a new domain</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <Form.Label>Enter the Domain you want to add</Form.Label>
                                            <Form.Control required onChange={(e) => setDomain(e.target.value)} />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShow(false)}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={addDomain}>
                                        Add
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={show2}
                                onHide={() => setShow2(false)}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm Delete</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to delete this?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShow2(false)}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={() => deleteDomain(index)}>Confirm</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={show3}
                                onHide={() => setShow3(false)}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title> <FontAwesomeIcon icon={faChalkboardUser} /> Instruction</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Add the domain using the Add a new Domain button.
                                    After adding the domain, log in to your domain provider such as GoDaddy, Hostinger etc..
                                    Configure the following in your domain provider's DNS settings
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><strong>RECORD TYPE</strong></td>
                                                    <td><strong>HOSTNAME</strong></td>
                                                    <td><strong>VALUE</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>A</td>
                                                    <td>@</td>
                                                    <td>3.128.150.68</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    After configuring  above settings, click on verify. If domain is pointing to the above IP address, then it will be marked as verified.
                                    <strong>Note : It may take few time for the DNS settings to change. Please wait before trying again.</strong>
                                </Modal.Body>
                                <Modal.Footer>

                                    <Button variant="primary" onClick={() => setShow3(false)}>Confirm</Button>
                                </Modal.Footer>
                            </Modal>

                            <div className="xl-70">
                                <div className="email-right-aside bookmark-tabcontent">
                                    <div className="card email-body radius-left">
                                        <div className="ps-0">
                                            <div className="tab-content">


                                                <div className="tab-pane fade active show" id="pills-created" role="tabpanel"
                                                    aria-labelledby="pills-created-tab">
                                                    <div className="card mb-0">
                                                        <div className="card-body p-0">
                                                            <div className="taskadd">
                                                                <div className="table-responsive">

                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr className='domain-tr-view'>
                                                                                <th>Domain Name</th>
                                                                                {/* <th></th> */}
                                                                                <th>Status</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {list.map((value, index) => (
                                                                                <tr key={index} >

                                                                                    {/* <td>
                                                                                        <h6 className="task_title_0">Domain</h6>
                                                                                    </td> */}
                                                                                    <td className='table-link-view'>

                                                                                        <p className="task_desc_0 donain_view_text">
                                                                                            {value.domain}
                                                                                        </p>

                                                                                    </td>
                                                                                    <td className='table-link-view-btn'>
                                                                                        <p className="task_desc_0">
                                                                                            {value.status}
                                                                                        </p>

                                                                                    </td>
                                                                                    <td className='action-domain'>
                                                                                        <div className="action-btn-box mr-4">
                                                                                        {(value.status === 'pending') ? <Button type='class-btn ' disabled={checking} onClick={() => { check(index) }}
                                                                                        > <FontAwesomeIcon icon={faIdBadge}/> Verify</Button> : null}
                                                                                        {/* <Spinner animation="border" />  */}
                                                                                        <button className="action-btn-item2" onClick={() => onclick(index)}><FontAwesomeIcon icon={faTrashCan} />Delete</button>
                                                                                        </div>
                                                                                    </td>


 

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </main>
                </div>
            </div >




        </>
    );
}

export default Manage;
