import React from 'react'
import { useHistory } from 'react-router-dom'
import './Nav.css'
import Sidebar2 from './Sidebar2'
import Hamburger from 'hamburger-react'
import { Link } from 'react-router-dom'


function Nav() {


    const history = useHistory()
    async function logout() {
        localStorage.clear()
        history.push('/login')
    }
    // const menuTrigger=()=>{
    //     alert("working")
    // }
    function openNav() {
        document.querySelector('.sideNav').classList.toggle('menu-open')
    }



    return (
        <>
            <nav className="navbar navbar-dark sticky-top flex-md-nowrap ">

                <div className='nav-block'>
                    <div className="humberger-menu2 d-block  plr--20">
                        <span onClick={openNav} className="menutrigger text-black"><Hamburger size={24} /></span>
                    </div>
                    <Link to='/dashboard'>
                        <a className="navbar-logo col-md-3 col-lg-2 mr-2 px-3" href="#!"><img style={{width:'150px'}} src='./assets/images/logo/BrandXR_footer.png' alt="bxr"></img></a>
                    </Link>
                </div>
                <div className='sideNav' id='sideNav' >
                    <Sidebar2 />

                </div>


                <ul className="navbar-nav px-3">
                    {/* <li>
                        <Button variant="primary" onClick={handleShow} className="me-2">
                            Button
                        </Button>
                        <Offcanvas show={show}  >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                Helloo
                            </Offcanvas.Body>

                        </Offcanvas>
                    </li> */}
                    <li className="nav-item text-nowrap">
                        <button className="nav-link dashboard-btn" onClick={logout}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                            <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>Sign out</button>
                    </li>
                </ul>

            </nav>



        </>

    )
}
export default Nav;