import React from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, ResponsiveContainer } from 'recharts';

function Graph() {
  const data = [{name: '1 July', UniqueCount: 0, TotalCount:0, amt: 0},{name: '2 July', UniqueCount: 0, TotalCount: 0, amt: 0}, {name: '3 July', UniqueCount:0, TotalCount: 0, amt: 0},{name: '4 July', UniqueCount: 0, TotalCount: 0, amt: 0},{name: '5 July', UniqueCount: 0, TotalCount: 0, amt: 0},{name: '6 July', UniqueCount: 0, TotalCount: 0, amt: 0},{name: '7 July', UniqueCount: 0, TotalCount: 0, amt: 0}];
  return (
    <ResponsiveContainer width= "95%"  height={400}>
    <LineChart width={1200} height={312} data={data}>
    <Line type="monotone" dataKey="UniqueCount" stroke="#8884d8" />
    <Line type="monotone" dataKey="TotalCount" stroke="#ff5252" />
    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
  </LineChart>
  </ResponsiveContainer>

  );
}

export default Graph;