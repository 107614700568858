import React from 'react'
import './listing.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { useState } from 'react'
import Nav from '../component/inner_pages/Nav'
import Sidebar from '../component/inner_pages/Sidebar'
import { faEdit, faEye, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
function Listing() {
    const [list, setList] = React.useState([])
    const [show, setShow] = useState(false)

    useEffect(() => {
        const getCampaigns = async () => {
            const query = queryString.parse(window.location.search)
            const url = query.url
            const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/getCampaigns?urlid=${url}`)
            const data = await response.json()
            if (data.status === 'ok')
                setList(data.list)
        }
        getCampaigns()
    }, [])


    return (
        <>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> <FontAwesomeIcon icon={faEdit} /> Edit Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Session</Form.Label>
                            <input type="text" className="form-control" />
                        </Form.Group>

                        <div >
                            <div className="modal-inner-content" >

                                <p style={{ fontSize: '15px' }}>UTMs can help you track web traffic in analytics tools.</p>
                            </div>



                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Source</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Medium</Form.Label>
                                <Form.Control as="textarea" rows={3}  />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                <Form.Label>Campaign</Form.Label>
                                <Form.Control as="textarea" rows={3}  />
                            </Form.Group>

                            <div className="utm-inputs-snack-bar">
                                <div className="orb-snack-bar info">
                                    <img alt="snack-bar-icon" src="./assets/images/media/info.svg" />
                                    <div className="message small">If you use UTMs, be sure to complete all 3 fields.</div>
                                </div>
                            </div>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>



                    <div>
                        <Button variant="secondary footer-model-btn mr-3" onClick={() => setShow(false)}>
                            Close
                        </Button>
                        <Button variant="primary">
                            Create
                        </Button>
                    </div>






                </Modal.Footer>
            </Modal>
            <div >
                <Nav />
                <div className="container-fluid">
                    <div className="row">

                        <Sidebar />
                        <main role="main" className="col-md-12 col-sm-12 ml-sm-auto col-lg-10 main-warp pt--100 page-body">
                            <div className='header-title-dashboard'>
                                <h4 className="h2"><FontAwesomeIcon icon={faChartLine} /> Metrics</h4>
                            </div>

                            <div className='card'>
                                <div className='card-body'>
                                    <div className='qr-list'>
                                        <div className="col-md-12">
                                            <div className="doc_list_step">
                                                <div className="doc_list_step_item">
                                                    <h5 className="card-title">List of all associated Sessions, each of which act as a unique bucket holder for metrics</h5>
                                                </div>
                                            </div>
                                            <div className="qrlist-container">
                                                <table>
                                                    <tbody>
                                                        {list.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className='qr-code-list-view campaign-view'>
                                                                    <h5 className='name'>Session Name</h5>
                                                                    <h5 className='name' >SmartLink</h5>
                                                                    <h5 className='time'>Start Date</h5>
                                                                    <h5 className='time'>End Date</h5>
                                                                </td>
                                                                <td className="qr-code-list-view listing-table-view">
                                                                    <div className="qr-code-list-view-box">

                                                                        <div className='qr-code-list-view-box-title'>
                                                                            <p className='url' style={{ fontSize: '18px' }}>{item.campaignName}</p>
                                                                            <p className='url' style={{ fontSize: '18px' }}> {process.env.REACT_APP_VISITOR_URL}/{item.code}</p>
                                                                            <p className='time' style={{ fontSize: '18px' }}>{item.startdate}</p>
                                                                            <p className='time' style={{ fontSize: '18px' }}>{item.enddate}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className="action-btn">
                                                                    <div className="action-btn-box">
                                                                        <button onClick={() => setShow(true)} className="action-btn-item-view domain_view_btn"><FontAwesomeIcon icon={faEdit} />Edit Session</button>
                                                                        <button className="action-btn-item-view  campaign_view_btn domain_view_btn"><Link to={`/statistics?code=${item.campaign}`}><FontAwesomeIcon icon={faEye} />View Stats</Link></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </main>
                    </div>
                </div>
            </div>

        </>
    )

}


export default Listing;
