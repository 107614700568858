import React from "react";
import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Header from '../component/header/HeaderFive';
import FooterTwo from "../component/footer/FooterTwo";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "react-fullscreen-loading";
import { Modal, Button } from "react-bootstrap";
function Signup() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const  [show, setShow] = useState(false)

    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const checktoken = async () => {
            const token = localStorage.getItem('token')
            if (token) {
                history.push('/dashboard')
            }
        }
        checktoken()
    })

    async function registerUser(e) {
        setLoading(true)
        e.preventDefault()
        if (password === cpassword) {
            const response = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    password,
                }),
            })

            const data = await response.json()
            setName('')
            setEmail('')
            setPassword('')
            setCpassword('')
            setLoading(false)
            if (data.status === 'verify email') {
                // navigate('/login')
                toast('Successfully registered. Verify Email')
            }
            else if (data.status === 'exists') {
                toast('Email Already exists')
            }
        }
        else {
            toast('Passwords do not match')
        }
    }

    return (
        <>
            <Loading loading={loading} loaderColor="#f16022" />;
            <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black" />
            <div>
                <ToastContainer />
                <Modal
                    show = {show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Centered Modal</h4>
                        <p>
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                            consectetur ac, vestibulum at eros.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShow(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <section className="signup ptb--90" >
                    {/* style="background-color: #eee; */}
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-lg-12 col-xl-11">
                                <div className="card text-black" >
                                    {/* style="border-radius: 25px;" */}
                                    <div className="card-body p-md-5">
                                        <div className="row justify-content-center">
                                            <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                                                <p className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>

                                                <form className="mx-1 mx-md-4" onSubmit={registerUser}>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example1c">Your Name</label>
                                                            <input type="text" id="form3Example1c" className="form-control" value={name}
                                                                onChange={(e) => setName(e.target.value)} required />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example3c">Your Email</label>
                                                            <input type="email" id="form3Example3c" className="form-control" value={email}
                                                                onChange={(e) => setEmail(e.target.value)} required />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example4c">Password</label>
                                                            <input type="password" id="form3Example4c" className="form-control" value={password}
                                                                onChange={(e) => setPassword(e.target.value)} required />

                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-row align-items-center mb-4">
                                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                                        <div className="form-outline flex-fill mb-0">
                                                            <label className="form-label" htmlFor="form3Example4cd">Repeat your password</label>
                                                            <input type="password" id="form3Example4cd" className="form-control" value={cpassword}
                                                                onChange={(e) => setCpassword(e.target.value)} required />

                                                        </div>
                                                    </div>

                                                    <div className="form-check d-flex mb-5">
                                                        <input className="form-check-input me-2 " type="checkbox" value="" id="form2Example3c" required />
                                                        <label className="form-check-label" htmlFor="form2Example3">
                                                            I agree all statements in <a href="#!" onClick={() => setShow(true)} className="btn-url">Terms of service</a>
                                                        </label>
                                                    </div>

                                                    <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                        <button type="submit" className="btn btn-primary btn-lg"  >Register</button>
                                                    </div>


                                                    <p className="text-center text-muted mt-5 mb-0">Have already an account? <a href="/login"
                                                        className="fw-bold text-body "><u>Login here</u></a></p>



                                                </form>

                                            </div>
                                            <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                                <img src="../assets/images/draw1.jpg"
                                                    className="img-fluid" alt="Sample" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterTwo />
        </>
    )

}
export default Signup;
