import React from 'react';
import { useState, useEffect } from 'react';
import Loading from "react-fullscreen-loading";

function Cards() {

    const [unique, setUnique] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    useEffect(() => {


        const getstatics = async () => {
            setLoading(true)
            const req = await fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/urls/statics`, {
                headers: {
                    'x-access-token': localStorage.getItem('token')
                }
            })
            const data = await req.json()

            if (data.status === 'ok') {
                setUnique(data.list[0].ucount)
                setTotal(data.list[0].tcount)
            } else if (data.status === 'not ok') {
                setUnique(0)
                setTotal(0)
            }
            setLoading(false)
        }
        getstatics()

    }, [])
    return (
        <>
            <Loading loading={loading} loaderColor="#f16022" />
            <div className="row">

                <div className="box-col-3 des-xl-25 rate-sec col-sm-12 col-md-12 col-xl-12 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="box">
                            <div className="numbers">
                                <p className="bottom-text">
                                    {total}
                                </p>
                            </div>
                            <div className="text-end">

                            </div>
                        </div>
                        <div className="footer-color-text bg-red">
                            <p className="text-sm mb-0 text-uppercase fontWeight-bold">Total VISITORS</p>

                        </div>
                    </div>

                </div>
                <div className='box-col-3 des-xl-25 rate-sec col-sm-12 col-md-12 col-xl-12 mb-xl-0 mb-4'>
                    <div className="card">
                        <div className="box">
                            <div className="numbers">
                                <p className="bottom-text">

                                    {unique}
                                </p>

                                
                            </div>
                            <div className="text-end">

                            </div>
                        </div>
                        <div className="footer-color-text bg-purpule">
                            <p className="text-sm mb-0 text-uppercase fontWeight-bold">Total UNIQUE VISITORS</p>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Cards;
