import React from "react";
import { useState } from "react";
import Header from '../component/header/HeaderFive';
import FooterTwo from "../component/footer/FooterTwo";

function Forgot() {
    const[email, setEmail] = useState('')

    async function resetPass(e) {
        e.preventDefault()
        setEmail('')

        fetch(`${process.env.REACT_APP_SSL_CHECK}://${process.env.REACT_APP_API_URL}/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        }).then((response) => {
            if(response.ok){
                alert('Please check your email')
            }
            else
            alert('some error has occurred')
        })

    }

    return (
        <>
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                <div>
                    <section className="signup ptb--90" >
                        {/* style="background-color: #eee; */}
                        <div className="container">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col-lg-12 col-xl-11">
                                    <div className="card text-black" >
                                        {/* style="border-radius: 25px;" */}
                                        <div className="card-body p-md-5">
                                            <div className="row justify-content-center">
                                                <div className="col-md-10 col-lg-7 col-xl-5 order-2 order-lg-1">

                                                    <p className="text-center h2 fw-bold mb-5 mx-1 mx-md-4 mt-4">Forgot Password?</p>

                                                    <form className="mx-1 mx-md-4" onSubmit={resetPass}>

                                                        <div className="d-flex flex-row align-items-center mb-4">
                                                            <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                                            <div className="form-outline flex-fill mb-0">
                                                                <label className="form-label" htmlFor="form3Example3c">Your Email</label>
                                                                <input type="email" id="form3Example3c" className="form-control" value={email}
                                                                onChange={(e) => setEmail(e.target.value)} required />
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center mb-3 mb-lg-4">
                                                            <button type="submit" className="btn btn-primary btn-lg" >Reset Password</button>
                                                        </div>

                                                    </form>

                                                </div>
                                                <div className="col-md-10 col-lg-5 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                                                    <img src="../assets/images/draw1.jpg"
                                                        className="img-fluid" alt="Sample_image" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <FooterTwo />
        </>
    )
}
export default Forgot;